<template>
  <div class="form-row">
    <div class="col-auto m-1">
      articles:
      <select
        id="limit-filter"
        :value="limit"
        name="limit-filter"
        class="form-control-sm"
        tabindex="-1"
        @change="updateLimit"
      >
        <option selected value="10">10</option>
        <option value="50">50</option>
        <option value="100">100</option>
        <option value="200">200</option>
        <option value="500">500</option>
      </select>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import xlibs from '../../js/xlibs'
import xcomputed from '../../js/xcomputed'

export default {
  name: 'ControlLimitFilter',
  props: [],
  computed: {
    isAdmin: xcomputed.isAdmin,
    isLive: xcomputed.isLive,
    isStatic: xcomputed.isStatic,
    ...mapGetters(['articles', 'articleFilters', 'curatingFrom', 'collectionType', 'collectionName']),
    ...mapState({
      limit: (state) => state.articleFilters.limit
    })
  },
  methods: {
    async updateLimit(e) {
      this.$store.dispatch('setArticleFilter', { limit: e.target.value })
      xlibs.applyFilters.apply(this)
    }
  }
}
</script>
