var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"mainNav navbar navbar-light"},[(_vm.isSiteHeader)?_c('div',{staticClass:"col offset-sm-2 col-sm-8",attrs:{"id":"alert-container"}}):_vm._e(),_vm._v(" "),(_vm.isSiteHeader)?_c('div',{staticClass:"container"},[_c('router-link',{staticClass:"navbar-brand slanted",class:{ static: _vm.isStatic },attrs:{"exact":"","tabindex":"-1","to":"/xman"}},[_vm._v("\n      XMAN "),(_vm.isStatic)?_c('span',{staticClass:"xman-mode"},[_vm._v("static")]):_vm._e()])],1):_vm._e(),_vm._v(" "),(_vm.isSiteHeader)?_c('div',{staticClass:"logout-nav"},[_c('ul',{staticClass:"nav nav-bar"},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"btn btn-sm btn-outline-primary",attrs:{"href":"#","tabindex":"-1"},on:{"click":function($event){return _vm.logout()}}},[_vm._v(" Logout ")])])])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"container"},[_c('ul',{staticClass:"nav"},[(_vm.isLive & _vm.isEntityCurator)?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link btn",class:{
            'btn-sm btn-outline-secondary': _vm.isSiteHeader,
            'btn-outline-primary': !_vm.isSiteHeader
          },attrs:{"active-class":"active","to":"/xman/live","tabindex":"-1"}},[_c('i',{staticClass:"ion-compose"}),_vm._v("Live Articles\n        ")])],1):_vm._e(),_vm._v(" "),(_vm.isLive & _vm.isSpotlightAnalyst)?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link btn",class:{
            'btn-sm btn-outline-secondary': _vm.isSiteHeader,
            'btn-outline-primary': !_vm.isSiteHeader
          },attrs:{"active-class":"active","to":"/xman/spotlights","tabindex":"-1"}},[_c('i',{staticClass:"ion-compose"}),_vm._v("Spotlight Review\n        ")])],1):_vm._e(),_vm._v(" "),(_vm.isStatic)?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link btn",class:{
            'btn-sm btn-outline-secondary': _vm.isSiteHeader,
            'btn-outline-primary': !_vm.isSiteHeader
          },attrs:{"active-class":"active","exact":"","to":"/xman/static","tabindex":"-1"}},[_c('i',{staticClass:"ion-compose"}),_vm._v("Static Articles\n        ")])],1):_vm._e(),_vm._v(" "),(_vm.isAdmin && _vm.isLive)?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link btn",class:{
            'btn-sm btn-outline-secondary': _vm.isSiteHeader,
            'btn-outline-primary': !_vm.isSiteHeader
          },attrs:{"active-class":"active","exact":"","to":"/xman/reports","tabindex":"-1"}},[_c('i',{staticClass:"ion-compose"}),_vm._v("Reports\n        ")])],1):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }