<template>
  <div
    v-if="curatingFrom !== 'incoming' && curatingFrom !== 'spotlightsPending'"
    class="col-auto my-3 mx-2 date-type-control"
  >
    <b-form-checkbox
      v-model="defaultDateOn"
      :disabled="reportDetailType === reportDetailTypes.summary"
      name="dateRangeType-button"
      tabindex="-1"
      switch
    >
      DateType: {{ getTitle(dateRangeType) }}
    </b-form-checkbox>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import xlibs from '../../js/xlibs'
import xconfig from '../../js/xconfig'
import xcomputed from '../../js/xcomputed'

export default {
  name: 'ControlDateType',
  computed: {
    isAdmin: xcomputed.isAdmin,
    isLive: xcomputed.isLive,
    isStatic: xcomputed.isStatic,
    ...mapGetters(['articleFilters', 'curatingFrom', 'collectionType', 'collectionName']),
    ...mapState({
      dateRangeType: (state) => state.articleFilters.sort.date.dateRangeType,
      reportDetailType: (state) => state.articleFilters.reportDetailType,
      sortDirection: (state) => state.articleFilters.sort.date.direction,
      reportEntityType: (state) => state.articleFilters.reportEntityType
    }),
    dateMode() {
      if (_.toLower(this.curatingFrom) === 'reports') {
        return _.toLower(this.reportEntityType)
      }
      return _.includes(_.toLower(this.curatingFrom), 'spotlight') ? 'spotlights' : 'articles'
    },
    dateTypes() {
      const datetypes = _.filter(xconfig.dateRangeTypes, this.dateMode)
      return datetypes
    },
    onDateTypeKey() {
      return `${this.dateMode}On`
    },
    onDateType() {
      const onType = _.find(this.dateTypes, this.onDateTypeKey)
      return onType
    },
    defaultDateOn: {
      get: function () {
        return this.dateRangeType === this.onDateType.key
      },
      set: function (newValue) {
        this.toggleDateType(newValue)
      }
    },
    reportDetailTypes() {
      return xconfig.reportDetailTypes
    }
  },
  mounted() {},
  methods: {
    getTitle(dateRangeType) {
      const type = _.find(this.dateTypes, { key: dateRangeType })
      return _.get(type, 'title')
    },
    toggleDateType(isOn) {
      const newDateRangeType = _.find(this.dateTypes, [this.onDateTypeKey, isOn])
      const sort = _.clone(this.articleFilters.sort)
      _.set(sort, 'date.dateRangeType', newDateRangeType.key)
      delete sort.field
      this.$store.dispatch('setArticleFilter', { sort })
      if (this.reportDetailType !== xconfig.reportDetailTypes.summary) {
        xlibs.applyFilters.call(this)
      }
    }
  }
}
</script>
