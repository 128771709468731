<template>
  <div class="polarity-cat-score">
    <span v-if="!isOriginal" class="original-score">
      Original:
      <span class="polarity-score" :class="scoreClass('originalScore')">{{
        _.isNil(originalScore) ? 'NA' : originalScore | round
      }}</span></span
    >
    <span v-if="!isOriginal" class="pl-2">Curation:</span>
    <span class="polarity-score" :class="[{ dirty: isDirty() }, scoreClass(), { notOriginal: !isOriginal }]">{{
      _.get(this, 'currentScore', 'NA') | round
    }}</span>
    <!-- <span v-if="!readOnly" class="score-selector">
      <b-form-select
        v-model="localSelected"
        class="level-selector"
        :class="[{ dirty: isDirty() }, selectedScoreClass()]"
        :options="formPolarityLevels"
      ></b-form-select>
    </span> -->
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import xentities from '../../js/xentities'
import xentitiesComputed from '../../js/xentitiesComputed'
import xconfig from '../../js/xconfig'
import xcomputed from '../../js/xcomputed'

const { pipelines } = xconfig

export default {
  name: 'SpotlightCatScore',
  filters: {
    round: function (value) {
      if (typeof value !== 'number') return value
      return Math.round(value)
    }
  },
  props: {
    entity: {
      type: Object,
      default: null
    },
    spotlightId: {
      type: String,
      default: null
    },
    spotlight: {
      type: Object,
      default: null
    },
    articleId: {
      type: String,
      default: null
    },
    originalScore: {
      type: Number,
      default: undefined
    },
    currentScore: {
      type: Number,
      default: undefined
    },
    lens: {
      type: String,
      default: null
    },
    category: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      initialCurationAction: ''
    }
  },
  computed: {
    ...xcomputed,
    ...xentitiesComputed,
    ...mapGetters([
      'articles',
      'count',
      'modalArticle',
      'articleFilters',
      'collectionType',
      'collectionName',
      'curatingFrom',
      'spotlightChanges',
      'spotlightCurationAction'
    ]),
    lensKey() {
      return `categories.${_.toUpper(this.lens)}scores`
    },
    lensScorePath() {
      return `${this.lensKey}.${this.category}`
    },
    spotlightChangePath() {
      return this.spotlightId
    },
    ...mapState({
      catValue(state) {
        const val = _.get(state, `spotlightChanges.${this.spotlightChangePath}.curated.score`)
        return val
      }
    }),
    initialScoreLevel() {
      const scoreLevel = this.getScorePolarityLevel(this.currentScore)
      return scoreLevel
    },
    formPolarityLevels() {
      const levels = _.map(xconfig.polarityScoreLevels, (polarityLevel) => {
        const initial = polarityLevel.level === this.initialScoreLevel ? ' *' : ''
        const text = `${polarityLevel.level.toString()}${initial}`
        return { value: polarityLevel.level, text }
      })
      return levels
    },
    readOnly() {
      if (this.curatingFrom === 'reports') return true
      return this.spotlightCurationLockCheck
    },
    pipelines() {
      return pipelines
    },
    isOriginal() {
      if (this.originalScore !== this.currentScore) {
        return false
      }
      return true
    },
    localSelected: {
      get: function () {
        const fromSelection = this.catValue
        const initial = this.initialScoreLevel
        const fromSelectionLevel = this.getScorePolarityLevel(fromSelection)
        const val = _.isNil(fromSelection) ? initial : fromSelectionLevel
        return val
      },
      set: function (newValue) {
        this.polarityChange(newValue)
      }
    }
  },
  mounted() {
    this.localSelected = this.initialScoreLevel
    this.initialCurationAction = _.get(this.spotlightCurationAction, this.spotlightId)
  },
  methods: {
    ...xentities,
    isDirty() {
      if (this.localSelected !== this.initialScoreLevel) return true
      return false
    },
    scoreClass(type = 'currentScore') {
      return this.getScorePolarityClass(_.get(this, type))
    },
    selectedScoreClass() {
      if (this.localSelected === this.initialScoreLevel) return 'no'
      return this.getScorePolarityClass(this.localSelected, 'level')
    },
    polarityChange(level) {
      let polarityLevel = level !== this.initialScoreLevel ? level : 'initial'
      const spotlightChangePath = this.spotlightChangePath
      if (level === this.initialScoreLevel) {
        this.$store.dispatch('clearSpotlightChange', { spotlightChangePath })
        if (!_.isEmpty(this.initialCurationAction)) {
          this.$store.dispatch('setSpotlightCurationAction', {
            spotlightId: this.spotlightId,
            curationAction: this.initialCurationAction
          })
        }
      } else {
        const originalScoreLevel = this.getScorePolarityLevel(this.originalScore)
        const newScore = xconfig.polarityLevel2Score[polarityLevel]
        const change = {
          curated: { score: newScore, level: polarityLevel },
          original: { score: this.originalScore, level: originalScoreLevel }
        }
        const difference = Math.abs(polarityLevel - originalScoreLevel)
        const curationAction =
          polarityLevel === 0 || difference > 1 ? xconfig.curationTypes.suppressed : xconfig.curationTypes.approved
        this.$store.dispatch('setSpotlightCurationAction', { spotlightId: this.spotlightId, curationAction })
        this.$store.dispatch('addSpotlightChange', { spotlightChangePath, change })
      }
    }
  }
}
</script>
