<template>
  <div class="form-row">
    <div class="col-auto m-1">
      SourceType:
      <select
        id="sourceType-filter"
        :value="sourceType"
        name="sourceType-filter"
        class="form-control-sm"
        tabindex="-1"
        @change="updateSourceType"
      >
        <option
          v-for="source in sourceTypes"
          :key="source.code"
          :value="source.code"
          :selected="source.code === sourceType"
        >
          {{ source.name }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import xlibs from '../../js/xlibs'
import xcomputed from '../../js/xcomputed'

export default {
  name: 'ControlSourceTypeilter',
  props: [],
  data() {
    return {
      sourceTypes: [{ code: 'all', name: 'all' }],
      sourceType: 'all'
    }
  },
  computed: {
    isAdmin: xcomputed.isAdmin,
    isLive: xcomputed.isLive,
    isStatic: xcomputed.isStatic,
    ...mapGetters(['articles', 'articleFilters', 'curatingFrom', 'collectionType', 'collectionName']),
    ...mapState({
      soureType: (state) => state.articleFilters.soureType
    })
  },
  mounted() {
    this.getSourceTypes()
  },
  methods: {
    async getSourceTypes() {
      const url = '/api/sourceTypes'
      const curationRequestType = xlibs.getCurationRequestType(this.curatingFrom)
      const params = { curatingFrom: curationRequestType }
      const { data } = await axios.get(url, { params }).catch((error) => {
        xlibs.requestFailure(error)
      })
      _.each(data, (key) => {
        const name = String(key)
        const obj = { code: name, name }
        this.sourceTypes.push(obj)
      })
    },
    async updateSourceType(e) {
      this.$store.dispatch('setArticleFilter', { sourceType: e.target.value })
      xlibs.applyFilters.apply(this)
    }
  }
}
</script>
