export default {
  articles: (state) => state.articles,
  modalArticle: (state) => state.modalArticle,
  excludeModalArticle: (state) => state.excludeModalArticle,
  count: (state) => state.count,
  articleFilters: (state) => state.articleFilters,
  curatingFrom: (state) => state.curatingFrom,
  collectionType: (state) => state.collectionType,
  collectionName: (state) => state.collectionName,
  expandedScores: (state) => state.expandedScores,
  polarityChanges: (state) => state.polarityChanges,
  spotlightChanges: (state) => state.spotlightChanges,
  spotlightCurationAction: (state) => state.spotlightCurationAction,
  spotlights: (state) => state.spotlights
}
