<template>
  <div class="col sortable" :class="containerClass" @click.prevent="toggleSort">
    {{ headerTitle }}
    <b-icon v-b-tooltip.hover :icon="iconType" font-scale="1.1" :title="tooltipTitle"></b-icon>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'LocalSortTableHeader',
  props: {
    containerClass: {
      type: String,
      default: null
    },
    fieldType: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapGetters(['articleFilters', 'curatingFrom']),
    ...mapState({
      dateRangeType: (state) => _.get(state, 'articleFilters.sort.date.dateRangeType'),
      sortField: (state) => _.get(state, 'articleFilters.sort.field.type'),
      fieldDirection: (state) => _.get(state, 'articleFilters.sort.field.direction'),
      sortDirection: (state) => _.get(state, 'articleFilters.sort.date.direction')
    }),
    iconType() {
      const arrowDirection = this.fieldDirection === 'desc' ? 'sort-alpha-up-alt' : 'sort-alpha-down'
      const type = this.sortField === this.fieldType ? arrowDirection : 'arrow-down-up'
      return type
    },
    headerTitle() {
      const title = _.capitalize(this.fieldType)
      return title
    },
    tooltipTitle() {
      return `Sort current results by ${this.headerTitle}`
    },
    spotlightMode() {
      return _.includes(_.toLower(this.curatingFrom), 'spotlight')
    }
  },
  methods: {
    async toggleSort() {
      const sortFieldDirection = this.fieldDirection === 'asc' ? 'desc' : 'asc'
      const fieldSort = { type: this.fieldType, direction: sortFieldDirection }
      const dispatchType = this.spotlightMode ? 'localSortSpotlights' : 'localSortArticles'
      this.$store.dispatch(dispatchType, fieldSort)
    }
  }
}
</script>
