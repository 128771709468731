<template>
  <div class="form-row">
    <div class="col-auto m-1">
      Language:
      <select
        id="language-filter"
        :value="language"
        name="language-filter"
        class="form-control-sm"
        tabindex="-1"
        @change="updateLanguage"
      >
        <option v-for="lang in languages" :key="lang.code" :value="lang.code" :selected="lang.code === language">
          {{ lang.name }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import xlibs from '../../js/xlibs'
import xconfig from '../../js/xconfig'
import xcomputed from '../../js/xcomputed'

export default {
  name: 'ControlLanguageFilter',
  props: [],
  data() {
    return {
      languages: []
    }
  },
  computed: {
    isAdmin: xcomputed.isAdmin,
    isLive: xcomputed.isLive,
    isStatic: xcomputed.isStatic,
    ...mapGetters(['articles', 'articleFilters', 'curatingFrom', 'collectionType', 'collectionName']),
    ...mapState({
      language: (state) => state.articleFilters.language
    })
  },
  mounted() {
    this.languages = xconfig.languages
  },
  methods: {
    async updateLanguage(e) {
      this.$store.dispatch('setArticleFilter', { language: e.target.value })
      xlibs.applyFilters.apply(this)
    }
  }
}
</script>
