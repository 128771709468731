<template>
  <div class="home-page">
    <div class="banner">
      <div class="container">
        <h1 class="logo-font">Welcome to XMAN <span v-if="isStatic">static</span></h1>
        <div class="home-nav">
          <NavHeader />
        </div>
      </div>
    </div>
    <curationStats />
    <div v-if="_.size(featureSwitchesOn)" class="bg-info text-white featureFlags">
      <b>Feature Flags On:</b>
      <ul>
        <li v-for="feature in featureSwitchesOn" :key="feature">
          {{ feature }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import NavHeader from '../components/navHeader'
import curationStats from '../components/curationStats'
import xlibs from '../js/xlibs'
import xcomputed from '../js/xcomputed'

export default {
  name: 'HomeLanding',
  components: {
    NavHeader,
    curationStats
  },
  data() {
    return {
      currentUser: {}
    }
  },
  computed: {
    isAdmin: xcomputed.isAdmin,
    isLive: xcomputed.isLive,
    isStatic: xcomputed.isStatic,
    featureSwitchesOn: xcomputed.featureSwitchesOn
  },
  mounted() {
    this.currentUser = xlibs.getUser()
  }
}
</script>
