<template>
  <div class="reports-page main-template" :class="{ 'spotlights-page': spotlightsTypeOn }">
    <div class="container-fluid form-controls">
      <div class="form-row date-row my-1">
        <div class="col-s-2">
          <h5>Report Type</h5>
        </div>
      </div>
    </div>
    <div class="container-fluid form-controls">
      <div class="form-row date-row my-1">
        <div class="col-s-2">
          <h6>Entity type:</h6>
        </div>
        <div class="col-sm">
          <b-form-radio-group
            id="btn-radios-reportEntityType"
            v-model="reportEntityTypeModel"
            :options="reportEntityTypeOptions"
            :button-variant="spotlightsTypeOn ? 'outline-primary' : 'outline-info'"
            size="sm"
            name="radio-btn-reportEntityType"
            buttons
          ></b-form-radio-group>
        </div>
      </div>
      <div class="form-row date-row my-1">
        <div class="col-s-2">
          <h6>Focus:</h6>
        </div>
        <div class="col-sm">
          <b-form-radio-group
            id="btn-radios-reportDetailType"
            v-model="reportDetailTypeModel"
            :options="reportDetailTypeOptions"
            button-variant="outline-dark"
            size="sm"
            name="radio-btn-reportDetailType"
            buttons
          ></b-form-radio-group>
        </div>
      </div>
      <controlDateFilter ref="controlDateFilter" :subtract-days-from-start="7" @preapply="resetPage" />
      <div v-if="reportDetailType === reportDetailTypes.summary" class="form-row date-row">
        <div v-if="dateRangeType === 'curationDate'" class="col col-md-12 d-flex curation-buttons">
          <button
            type="button"
            class="btn btn-sm mt-auto"
            :class="spotlightsTypeOn ? 'btn-primary' : 'btn-info'"
            tabindex="-1"
            :disabled="disableReport(disableReportDurations.curatorsReport)"
            @click="getCuratorsReport()"
          >
            {{ _.capitalize(reportEntityType) }} Curators Report
          </button>

          <button
            v-if="reportEntityType !== 'spotlights'"
            type="button"
            class="btn btn-sm btn-info mt-auto"
            tabindex="-1"
            :disabled="disableReport(disableReportDurations.versionsReport)"
            @click="getVersionsReport()"
          >
            {{ _.capitalize(reportEntityType) }} Versions Report
          </button>
        </div>
      </div>
      <div v-if="reportDetailType === reportDetailTypes.detail">
        <div class="form-row bg-light mt-3 ml-5 mr-5">
          <controlCompanyFilter v-if="!spotlightsTypeOn" />
        </div>
        <div class="form-row bg-light ml-5 mr-5">
          <controlPipelineFilter v-if="spotlightsTypeOn" />
          <controlLanguageFilter v-if="!spotlightsTypeOn" />
          <controlSourceTypeFilter v-if="!spotlightsTypeOn" />
          <div class="col-auto my-1">
            Curation Type:
            <select
              id="curation-filter"
              :value="curation"
              name="curation-filter"
              class="form-control-sm"
              tabindex="-1"
              @change="updateCuration"
            >
              <option value="curated">all</option>
              <option v-if="!spotlightsTypeOn" value="excluded">excluded</option>
              <option v-if="!spotlightsTypeOn" value="ingested">ingested</option>
              <option v-if="spotlightsTypeOn" value="approved">approved</option>
              <option v-if="spotlightsTypeOn" value="suppressed">suppressed</option>
            </select>
          </div>
        </div>
        <controlUserFilter class="bg-light ml-5 mr-5 mt-auto" :spotlight-mode="spotlightsTypeOn" />
        <controlSpotlightId v-if="spotlightsTypeOn" class="bg-light ml-5 mr-5 mt-auto" />
        <div class="form-row bg-light ml-5 mr-5">
          <div class="col-auto my-1 center">
            <button
              type="button"
              class="btn btn-sm btn-view-articles"
              :class="spotlightsTypeOn ? 'btn-primary' : 'btn-info'"
              tabindex="-1"
              @click="showArticles"
            >
              {{ reportEntityType }} view
            </button>
            <button
              type="button"
              class="btn btn-sm btn-primary"
              tabindex="-1"
              :disabled="disableReport(_.get(disableReportDurations, ['reportItemsDownload', reportEntityType]))"
              @click="downloadMainCSV()"
            >
              {{ reportEntityType }} CSV
            </button>
          </div>
        </div>
      </div>
      <div v-if="reportDetailType === reportDetailTypes.change" class="form-row date-row">
        <button
          type="button"
          class="btn btn-sm mt-auto"
          :class="'btn-info'"
          tabindex="-1"
          :disabled="spotlightsTypeOn"
          @click="getchangeReports()"
        >
          {{ _.capitalize(reportEntityType) }} Change Report
        </button>
      </div>
    </div>
    <div
      v-if="showReportItemList && !curatorsReport && !versionsReport && !changeReports && limit !== 0"
      class="container-fluid count"
    >
      showing {{ count.currentCount }} of {{ count.totalCount }} {{ reportEntityType }}
    </div>
    <div id="search-spinner" class="container-fluid">
      <div class="col screen-centered"><div class="spinner" /></div>
    </div>
    <div
      v-if="showReportItemList && articles.length && !curatorsReport && !versionsReport && limit !== 0"
      class="container-fluid page articles"
    >
      <div class="row article-header sticky-top">
        <div class="col col-sm-1 actionCol">Curation</div>
        <sortDateTableHeader container-class="col-sm-1 pubDateCol" date-type="articlePubDate" />
        <sortDateTableHeader container-class="col-sm-1 curationDateCol" date-type="curationDate" />
        <localSortTableHeader container-class="col-sm-2 titleCol" field-type="headline" />
        <localSortTableHeader container-class="col-sm-1 sourceCol" field-type="source" />
        <div class="col col-sm-6 entitiesCol entitiesSection"><entitiesTableHeader /></div>
      </div>
      <div
        v-for="(article, articleIndex) in articles"
        :id="'article-' + article._id"
        :key="article._id"
        class="row article"
      >
        <div class="col col-sm-3 curationSection">
          <div class="row">
            <div class="col col-sm-3 actionCol text-center">
              <span v-if="!article.excluded" class="approved"><i class="fa fa-check-circle" /></span>
              <span v-if="article.excluded" class="excluded"><i class="fa fa-times-circle" /></span>
            </div>
            <div class="col col-sm-4 pubDateCol">
              {{ formattedDate(article.articlePubDateMs) }}
              <div class="local-time">local: {{ formattedDate(article.articlePubDateMs, true) }}</div>
              <documentHashCopy :document-hash="article.documentHash" />
            </div>
            <div class="col col-sm-5 curationDateCol">
              <curationDateInfo :article="article" :show-document-hash="false" />
            </div>
          </div>
        </div>
        <div class="col col-sm-3 articleDetailsSection">
          <div class="row">
            <div class="col article-body">
              <div class="col titleCol">
                <a
                  data-toggle="modal"
                  data-target="#articleModal"
                  href="#"
                  tabindex="-1"
                  @click="openArticle(article)"
                  >{{ getTitle(article) }}</a
                >
              </div>
              <div class="sourceCol float-right rounded border border-info p-1">
                <span class="source">{{ _.get(article, 'source.name', article.source) }}</span
                ><br />
                <span class="sourceType">{{ article.sourceType }}</span
                ><br />
                <span class="humanLanguage badge badge-info text-uppercase">{{
                  getLanguage(article.humanLanguage)
                }}</span>
                <country-flag :country="getFlag(article)" size="big" class="language-flag" />
              </div>
            </div>
          </div>
        </div>
        <div class="col col-sm-6 entitiesSection">
          <entitiesSection :article="article" :article-index="articleIndex" />
        </div>
      </div>
      <div v-if="!articles.length && !curatorsReport && !versionsReport" id="noArticles" class="row">
        <div class="col screen-centered"><b>No Articles Found</b></div>
      </div>
      <div v-if="showLoadMore" id="moreArticles" class="row">
        <div class="col screen-centered">
          <button type="button" class="btn-sm btn-primary" @click="loadMoreArticles">load more ...</button>
        </div>
      </div>
    </div>
    <div
      v-if="showReportItemList && spotlights.length && !curatorsReport && !versionsReport && limit !== 0"
      class="container-fluid page spotlights"
      :class="currentPipeline"
    >
      <div class="row spotlight-header sticky-top">
        <div class="col col-sm-1 curationSection">
          <div class="row curationRow">
            <sortDateTableHeader container-class="col-sm-12 curationDateCol" />
          </div>
        </div>
        <div class="col col-sm-6 spotlightDetailsSection">
          <div class="row spotlightsRow">
            <localSortTableHeader container-class="titleCol float-left my-col" field-type="headline" />
            <localSortTableHeader container-class="sourceCol float-right col-3 text-right" field-type="source" />
          </div>
        </div>
        <div class="col col-sm-5 entitiesSection"></div>
      </div>
      <div
        v-for="(spotlight, spotlightIndex) in spotlights"
        :id="'spotlight-' + spotlight._id"
        :key="spotlight._id"
        v-inview:class="{ onpage: true }"
        v-inview:class.leave="{ onpage: false }"
        class="row spotlight"
        :spotlight="spotlight"
        :class="{ disabled: !_.isEmpty(spotlight.curationDisabled) }"
      >
        <div class="col col-sm-1 curationSection">
          <div class="row">
            <div class="col col-sm-12 curationDateCol">
              <curationDateInfo :article="spotlight" :spotlight-id="spotlight.spotlightId" />
            </div>
          </div>
        </div>

        <div class="col col-sm-6 spotlightDetailsSection">
          <div class="row">
            <div class="col spotlight-body">
              <div class="titleCol">
                <a
                  data-toggle="modal"
                  data-target="#articleModal"
                  tabindex="-1"
                  href="#"
                  @click="openArticle(_.get(spotlight, 'article'))"
                  >{{
                    _.get(spotlight, 'article.title') || _.get(spotlight, 'article.headline_en', 'article not found')
                  }}</a
                >
              </div>
              <hr />
              <span
                v-dompurify-html="
                  getSummaryText({ article: spotlight.article, displayArticle: spotlight.displayArticle, html: true })
                "
                class="summaryCol"
              >
              </span>
            </div>
          </div>
        </div>
        <div class="col col-sm-5 entitiesSection">
          <spotlightEntity
            :article-index="spotlightIndex"
            :curating-from="curatingFrom"
            :spotlight-lens="spotlight.lens"
            :spotlight="spotlight"
          />
        </div>
      </div>
      <div v-if="_.get(spotlights, 'length') === 0" id="noSpotlights" class="row">
        <div class="col screen-centered"><b>No Spotlights Found</b></div>
      </div>
    </div>
    <div v-if="curatorsReport" class="container-fluid curatorsReport mt-4">
      <div class="row">
        <div class="col col-auto h5">
          {{ _.capitalize(reportEntityType) }} Curators Report (attributed to first curator/date)
        </div>
        <div v-if="_.get(curatorsReport, 'curators.length')" class="col col-auto">
          <button type="button" class="btn btn-sm btn-primary mt-auto" @click="downloadCuratorCSV()">
            {{ _.capitalize(reportEntityType) }} Curators CSV Download
          </button>
        </div>
      </div>
      <div v-for="record in curatorsReport.curators" :key="record.curator" class="row">
        <table class="table table-responsive table-bordered table-sm report-table">
          <thead class="thead-light">
            <tr>
              <th>{{ record.curator }}</th>
              <th v-for="day in record.curationDays" :key="day.date">
                {{ day.date }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ spotlightsTypeOn ? 'Suppressed' : 'Excluded' }} ({{ record.excluded }})</td>
              <td v-for="day in record.curationDays" :key="day.date">
                {{ day.excluded }}
              </td>
            </tr>
            <tr>
              <td>{{ spotlightsTypeOn ? 'Approved' : 'Ingested' }} ({{ record.ingested }})</td>
              <td v-for="day in record.curationDays" :key="day.date">
                {{ day.ingested }}
              </td>
            </tr>
            <tr>
              <td>Total ({{ record.total }})</td>
              <td v-for="day in record.curationDays" :key="day.date">
                {{ day.total }}
              </td>
            </tr>
            <tr>
              <td>{{ spotlightsTypeOn ? 'Suppression' : 'Exclusion' }} Rate ({{ record.exclusionRate }})</td>
              <td v-for="day in record.curationDays" :key="day.date">
                <span v-if="_.has(day, 'exclusionRate')">{{ day.exclusionRate }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="versionsReport" class="container-fluid curatorsReport mt-4">
      <div class="row">
        <div class="col col-auto h5">Versions Report</div>
        <div v-if="_.get(versionsReport, 'total.length')" class="col col-auto">
          <button type="button" class="btn btn-sm btn-primary mt-auto" @click="downloadVersionsCSV()">
            versions CSV
          </button>
        </div>
      </div>
      <div v-for="(report, reportIndex) in versionsReport" :key="reportIndex" class="row">
        <table v-if="_.get(report, 'length')" class="table table-responsive table-bordered table-sm report-table">
          <thead class="thead-light">
            <tr>
              <th>{{ _.first(_.split(reportIndex, '_', 1)) }} {{ _.split(reportIndex, '_', 2)[1] }}</th>
              <th v-for="day in report" :key="day.date">
                <small
                  ><b>{{ day.date }}</b></small
                >
              </th>
            </tr>
          </thead>
          <tbody v-if="_.startsWith(reportIndex, 'differenceReport')">
            <tr>
              <td>Total Difference: {{ _.last(_.split(reportIndex, '_', 2)) }}</td>
              <td v-for="day in report" :key="day.date">
                {{ day.difference }}
              </td>
            </tr>
          </tbody>
          <tbody v-if="reportIndex === 'totalAdded'">
            <tr>
              <td>Total Articles Added</td>
              <td v-for="day in report" :key="day.date">
                {{ day.total }}
              </td>
            </tr>
            <tr>
              <td>Net Difference</td>
              <td v-for="day in report" :key="day.date">
                {{ day.difference }}
              </td>
            </tr>
          </tbody>
          <tbody v-if="!_.startsWith(reportIndex, 'differenceReport') && reportIndex !== 'totalAdded'">
            <tr>
              <td>{{ reportIndex }} Excluded</td>
              <td v-for="day in report" :key="day.date">
                {{ day.excluded }}
              </td>
            </tr>
            <tr>
              <td>{{ reportIndex }} Ingested</td>
              <td v-for="day in report" :key="day.date">
                {{ day.ingested }}
              </td>
            </tr>
            <tr>
              <td>{{ reportIndex }} Articles Reviewed</td>
              <td v-for="day in report" :key="day.date">
                {{ day.total }}
              </td>
            </tr>
            <tr>
              <td>{{ reportIndex }} Exclusion Rate</td>
              <td v-for="day in report" :key="day.date">
                <span v-if="_.has(day, 'exclusionRate')">{{ day.exclusionRate }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="changeReports" class="container-fluid changeReports mt-4">
      <div class="row">
        <div class="col col-auto h5">{{ _.capitalize(reportEntityType) }} Change Report Links</div>
      </div>
      <div class="row mx-1">
        <div v-if="_.get(changeReports, 'length')" class="col col-auto">
          <div v-for="changeReport in changeReports" :key="changeReport.fileName" class="row">
            <div class="col col-auto">
              <a :href="changeReport.url">{{ changeReport.fileName }}</a>
            </div>
            <div class="col col-auto">
              <i>( Report Generated {{ changeReport.lastModified }} UTC )</i>
            </div>
          </div>
        </div>
      </div>
      <div class="row m-3">
        <div class="col col-auto">* Links may expire in an hour. Click Change Report button to generate new links.</div>
      </div>
    </div>
    <articleModal />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import controlCompanyFilter from '../components/formControls/controlCompanyFilter'
import controlDateFilter from '../components/formControls/controlDateFilter'
import controlLanguageFilter from '../components/formControls/controlLanguageFilter'
import controlPipelineFilter from '../components/formControls/controlPipelineFilter'
import controlSourceTypeFilter from '../components/formControls/controlSourceTypeFilter'
import controlSpotlightId from '../components/formControls/controlSpotlightId'
import controlUserFilter from '../components/formControls/controlUserFilter'
import documentHashCopy from '../components/documentHashCopy'
import articleModal from '../components/articleModal'
import entitiesSection from '../components/entites/entitiesSection'
import entitiesTableHeader from '../components/entites/entitiesTableHeader'
import sortDateTableHeader from '../components/sortDateTableHeader'
import spotlightEntity from '../components/entites/spotlightEntity'
import localSortTableHeader from '../components/localSortTableHeader'
import curationDateInfo from '../components/curationDateInfo'
import xconfig from '../js/xconfig'
import xlibs from '../js/xlibs'
import xcomputed from '../js/xcomputed'
import xentitiesComputed from '../js/xentitiesComputed'

export default {
  name: 'Reports',
  components: {
    controlCompanyFilter,
    controlDateFilter,
    controlLanguageFilter,
    controlPipelineFilter,
    controlSourceTypeFilter,
    controlSpotlightId,
    controlUserFilter,
    curationDateInfo,
    documentHashCopy,
    entitiesSection,
    entitiesTableHeader,
    sortDateTableHeader,
    spotlightEntity,
    localSortTableHeader,
    articleModal
  },
  data() {
    return {
      changeReports: null,
      curatorsReport: null,
      versionsReport: null,
      showReportItemList: true,
      disableReportDurations: [],
      reportEntityType: 'articles',
      selected: 'radio1',
      reportEntityTypeOptions: [
        { text: 'Articles', value: 'articles' },
        { text: 'Spotlights', value: 'spotlights' }
      ],
      reportDetailType: xconfig.reportDetailTypes.detail,
      reportDetailTypeOptions: [
        { text: 'Details', value: xconfig.reportDetailTypes.detail },
        { text: 'Summary', value: xconfig.reportDetailTypes.summary },
        { text: 'Change', value: xconfig.reportDetailTypes.change }
      ]
    }
  },
  computed: {
    ...xcomputed,
    ...xentitiesComputed,
    ...mapGetters(['articles', 'spotlights', 'count', 'modalArticle', 'articleFilters']),
    ...mapState({
      dateRangeType: (state) => _.get(state, 'articleFilters.sort.date.dateRangeType'),
      curation: (state) => state.articleFilters.curation,
      limit: (state) => state.articleFilters.limit
    }),
    curatingFromDefault() {
      return this.curatingFrom
    },
    spotlightsTypeOn: {
      get: function () {
        return this.reportEntityType === 'spotlights'
      }
    },
    reportEntityTypeModel: {
      get: function () {
        return this.reportEntityType
      },
      set: async function (value) {
        this.reportEntityType = value
        await this.$store.dispatch('resetArticleFilters', 'reports')
        await this.$store.dispatch('setArticleFilter', {
          reportEntityType: this.reportEntityType,
          reportDetailType: this.reportDetailType
        })
        this.setCuratingFrom(this.curatingFrom)
        await this.resetPage()
        // switch primary pipeline for entity type
        const localPipeline = this.reportEntityType === 'articles' ? 'v2' : 'v3'
        this.$store.dispatch('setArticleFilter', { pipeline: localPipeline })
        this.$refs.controlDateFilter.resetDates()
      }
    },
    reportDetailTypeModel: {
      get: function () {
        return this.reportDetailType
      },
      set: async function (value) {
        this.reportDetailType = value
        await this.$store.dispatch('setArticleFilter', { reportDetailType: this.reportDetailType })
        if (this.reportDetailType === xconfig.reportDetailTypes.summary) {
          const sort = { date: { dateRangeType: 'curationDate' } }
          this.$store.dispatch('setArticleFilter', { sort })
        }
        await this.resetPage()
      }
    },
    reportDetailTypes() {
      return xconfig.reportDetailTypes
    }
  },
  created() {
    window.addEventListener('keydown', this.keyNavigation)
    this.$store.dispatch('resetArticleFilters', 'reports')
    this.setCuratingFrom(this.curatingFrom)
    this.resetPage()
  },
  destroyed() {
    window.removeEventListener('keydown', this.keyNavigation)
  },
  mounted() {
    this.disableReportDurations = xconfig.disableReportDurations
  },
  methods: {
    ...xlibs,
    async clearReports() {
      this.changeReports = null
      this.curatorsReport = null
      this.versionsReport = null
      this.showReportItemList = true
      await this.clearStores()
    },
    disableReport(duration) {
      const mStart = moment(this.articleFilters.startDate, xconfig.dateFormat)
      const mEnd = moment(this.articleFilters.endDate, xconfig.dateFormat)
      const dayDifference = mEnd.diff(mStart, 'days')
      return dayDifference > duration || dayDifference < 0
    },
    async resetPage() {
      await this.clearReports()
    },
    updateCuration(e) {
      this.$store.dispatch('setArticleFilter', { curation: e.target.value })
      xlibs.applyFilters.apply(this)
    },
    updateUser(user) {
      this.$store.dispatch('setArticleFilter', { user })
    },
    async showArticles() {
      this.clearReports()
      xlibs.applyFilters.call(this)
    },
    loadMoreArticles() {
      this.$store.dispatch('setArticleFilter', { page: this.articleFilters.page + 1 })
      xlibs.toPrevItem()
      xlibs.applyFilters.call(this)
    },
    async getCuratorsReport() {
      const self = this
      self.clearReports()
      const url = '/api/curation/report/users'

      const params = xlibs.getFormParams(self, self.articleFilters.page)
      xlibs.showSearchSpinner()
      const { data } = await axios
        .get(url, { params })
        .catch((error) => {
          xlibs.requestFailure(error)
        })
        .finally(() => {
          xlibs.showSearchSpinner(false)
        })
      self.curatorsReport = data
    },
    async getVersionsReport() {
      const self = this
      self.clearReports()
      const url = '/api/curation/report/versions'

      const params = xlibs.getFormParams(self, self.articleFilters.page)
      xlibs.showSearchSpinner()
      const { data } = await axios
        .get(url, { params })
        .catch((error) => {
          xlibs.requestFailure(error)
        })
        .finally(() => {
          xlibs.showSearchSpinner(false)
        })
      self.versionsReport = data
    },
    async getchangeReports() {
      const self = this
      self.clearReports()
      const url = '/api/curation/report/changes'

      const params = xlibs.getFormParams(self, self.articleFilters.page)
      xlibs.showSearchSpinner()
      const { data } = await axios
        .get(url, { params })
        .catch((error) => {
          xlibs.requestFailure(error)
        })
        .finally(() => {
          xlibs.showSearchSpinner(false)
        })
      self.changeReports = data
    },
    generateCuratorRow(label, records, key) {
      const formattedRow = [label]
      _.each(records, function (record) {
        formattedRow.push(_.get(record, key))
      })
      return formattedRow
    },
    downloadCuratorCSV() {
      const self = this
      if (!_.get(self, 'curatorsReport.curators.length')) {
        xlibs.showErrorAlert('No records found for CSV')
        return
      }
      const csvBody = [[]]
      const headerRow = self.generateCuratorRow('Date', self.curatorsReport.curators[0].curationDays, 'date')
      csvBody.push(headerRow)
      _.each(self.curatorsReport.curators, function (curatorSection) {
        csvBody.push([curatorSection.curator])
        csvBody.push(
          self.generateCuratorRow(
            `${self.spotlightsTypeOn ? 'Suppressed' : 'Excluded'} (${curatorSection.excluded})`,
            curatorSection.curationDays,
            'excluded'
          )
        )
        csvBody.push(
          self.generateCuratorRow(
            `${self.spotlightsTypeOn ? 'Approved' : 'Ingested'} (${curatorSection.ingested})`,
            curatorSection.curationDays,
            'ingested'
          )
        )
        csvBody.push(self.generateCuratorRow(`Total (${curatorSection.total})`, curatorSection.curationDays, 'total'))
        csvBody.push(
          self.generateCuratorRow(
            `${self.spotlightsTypeOn ? 'Suppression' : 'Exclusion'} Rate (${curatorSection.exclusionRate})`,
            curatorSection.curationDays,
            'exclusionRate'
          )
        )
        csvBody.push([])
      })
      const csvSheet = window.Papa.unparse(csvBody)
      xlibs.downloadCSVFile(csvSheet, 'curator')
    },
    downloadVersionsCSV() {
      const self = this
      if (!_.get(self, 'versionsReport.total.length')) {
        xlibs.showErrorAlert('No records found for CSV')
        return
      }
      const csvBody = [[]]
      const headerRow = self.generateCuratorRow('Date', self.versionsReport.total, 'date')
      csvBody.push(headerRow)
      _.each(self.versionsReport, function (report, index) {
        if (!_.isArray(report)) {
          return
        }
        if (_.startsWith(index, 'differenceReport')) {
          const versions = _.last(_.split(index, '_', 2))
          csvBody.push(self.generateCuratorRow(`Article Difference: ${versions} `, report, 'difference'))
        } else if (index === 'totalAdded') {
          const previousRow = _.last(csvBody)
          if (!_.isEmpty(previousRow)) {
            csvBody.push([])
          }
          csvBody.push(self.generateCuratorRow(`Total Articles Added`, report, 'total'))
          csvBody.push(self.generateCuratorRow(`Net Difference`, report, 'difference'))
        } else {
          const previousRow = _.last(csvBody)
          if (!_.isEmpty(previousRow)) {
            csvBody.push([])
          }
          csvBody.push(
            self.generateCuratorRow(`${index} ${self.spotlightsTypeOn ? 'Suppressed' : 'Excluded'}`, report, 'excluded')
          )
          csvBody.push(
            self.generateCuratorRow(`${index} ${self.spotlightsTypeOn ? 'Approved' : 'Ingested'}`, report, 'ingested')
          )
          csvBody.push(self.generateCuratorRow(`${index} Articles Total`, report, 'total'))
          csvBody.push(
            self.generateCuratorRow(
              `${index} ${self.spotlightsTypeOn ? 'Suppression' : 'Exclusion'} Rate`,
              report,
              'exclusionRate'
            )
          )
          csvBody.push([])
        }
      })
      const csvSheet = window.Papa.unparse(csvBody)
      xlibs.downloadCSVFile(csvSheet, 'curator')
    },
    async downloadMainCSV() {
      this.$store.dispatch('setArticleFilter', { limit: 0 })
      await xlibs.applyFilters.call(this)
      if (this.reportEntityType === 'articles') {
        this.generateArticlesCSV()
      }
      if (this.reportEntityType === 'spotlights') {
        this.generateSpotlightsCSV()
      }
      this.$store.dispatch('setArticleFilter', { limit: 50 })
    },
    formatExclusionBoolean(excluded) {
      if (excluded === true) {
        return 'excluded'
      }
      if (excluded == false) {
        return 'ingested'
      }
      return excluded
    },
    generateArticleRow(article, entityId, entityName, entitySummText, sasbCats, sdgCats, entityExcluded) {
      const formattedRow = []
      const exclusionReasonsString = _.map(article.exclusionReasons, (reason) => {
        return _.isString(reason) ? reason : JSON.stringify(reason)
      })
      const formatPubDate = article.articlePubDateMs ? moment.utc(article.articlePubDateMs).toJSON() : ''
      formattedRow.push(article._id)
      formattedRow.push(article.documentHash)
      formattedRow.push(xlibs.getTitle(article))
      formattedRow.push(formatPubDate)
      formattedRow.push(entitySummText)
      formattedRow.push(article.sourceType)
      formattedRow.push(entityId)
      formattedRow.push(entityName)
      formattedRow.push(sasbCats)
      formattedRow.push(sdgCats)
      formattedRow.push(this.formatExclusionBoolean(entityExcluded))
      formattedRow.push(this.formatExclusionBoolean(article.excluded))
      formattedRow.push(exclusionReasonsString)
      formattedRow.push(article.curation.curatorEmail)
      formattedRow.push(moment(article.curation.dateMs).format('YYYY-MM-DD'))
      return formattedRow
    },
    appendCatToString(catString = '', catName) {
      if (!_.isEmpty(catString)) {
        // add a comma
        catString = `${catString}, `
      }
      return `${catString}${catName}`
    },
    generateArticlesCSV() {
      const self = this
      const csvBody = [
        [
          'articleId',
          'documentHash',
          'title',
          'articlePubDate',
          'summText',
          'source',
          'ISIN/ID',
          'displayName',
          'sasbCats',
          'sdgCats',
          'companyCuration',
          'articleCuration',
          'exclusionReasons',
          'curatorEmail',
          'curationDate'
        ]
      ]
      if (this.count.totalCount < 1) {
        xlibs.showErrorAlert('No articles found for CSV')
        return
      }
      _.each(this.articles, function (article) {
        // v2
        if (self.articleFilters.pipeline && _.has(article, ['dpv2', 'associationTags'])) {
          _.each(_.get(article, 'dpv2.associationTags', []), function (tag) {
            if (tag.longName && tag.tvlId) {
              let sasbCatsV2 = '',
                sdgCatsV2 = ''
              _.each(_.get(tag, 'categories.SASBscores'), function (score, catName) {
                sasbCatsV2 = self.appendCatToString(sasbCatsV2, catName)
              })
              _.each(_.get(tag, 'categories.SDGscores'), function (score, catName) {
                sdgCatsV2 = self.appendCatToString(sdgCatsV2, catName)
              })
              const formattedRowV2 = self.generateArticleRow(
                article,
                tag.tvlId,
                tag.longName,
                xlibs.getSummaryText({ article }),
                sasbCatsV2,
                sdgCatsV2,
                tag.excluded
              )
              csvBody.push(formattedRowV2)
            }
          })
        }
      })
      const csvSheet = window.Papa.unparse(csvBody)
      xlibs.downloadCSVFile(csvSheet, 'curatedArticles')
    },
    generateSpotlightRow(spotlight) {
      const textField = this.articleFilters.pipeline === 'v3' ? 'full_text_en' : 'summText'
      const titleField = this.articleFilters.pipeline === 'v3' ? 'headline_en' : 'title'
      const formattedRow = []
      formattedRow.push(spotlight.spotlightId)
      formattedRow.push(spotlight.lens)
      formattedRow.push(moment(spotlight.hourMs).format('YYYY-MM-DD'))
      formattedRow.push(moment(_.get(spotlight, 'curation.dateMs')).format('YYYY-MM-DD'))
      formattedRow.push(_.get(spotlight, 'curation.curationType'))
      formattedRow.push(_.get(spotlight, 'curation.curatorId'))
      formattedRow.push(_.get(spotlight, 'curation.curatorEmail'))
      const entity = _.find(_.get(spotlight, 'article.associationTags'), { tvlId: spotlight.orgTvlId })
      const entityName = _.get(entity, 'longName')
      const category = _.get(spotlight, 'individualCategory')
      const lensKey = `${_.toUpper(spotlight.lens)}scores`
      const entityScore = _.get(entity, ['categories', lensKey, category])
      formattedRow.push(entityName)
      formattedRow.push(spotlight.orgTvlId)
      formattedRow.push(category)
      formattedRow.push(entityScore)
      formattedRow.push(_.get(spotlight, `article.${titleField}`))
      formattedRow.push(_.get(spotlight, `article.${textField}`))
      return formattedRow
    },
    generateSpotlightsCSV() {
      const self = this
      const csvBody = [
        [
          'spotlightId',
          'lens',
          'triggerDate',
          'curationDate',
          'curationStatus',
          'curatorId',
          'curatorEmail',
          'companyName',
          'orgTvlId',
          'category',
          'categoryScore',
          'title',
          'articleText'
        ]
      ]
      if (this.count.totalCount < 1) {
        xlibs.showErrorAlert('No articles found for CSV')
        return
      }
      _.each(this.spotlights, function (spotlight) {
        const formattedRow = self.generateSpotlightRow(spotlight)
        csvBody.push(formattedRow)
      })
      const csvSheet = window.Papa.unparse(csvBody)
      xlibs.downloadCSVFile(csvSheet, 'curatedSpotlights')
    }
  }
}
</script>
