<template>
  <div class="entities" :class="currentPipeline">
    <table class="table entities-cats-matrix" style="table-layout: fixed">
      <thead>
        <tr>
          <th
            v-for="entity in localEntities"
            :key="`head_${articleIndex}_${entity.tvlId}_${currentPipeline}`"
            scope="col"
            class="entityHeader px-0 sticky-top"
            :class="currentPipeline"
          >
            <div v-if="entity.excluded" class="entity-excluded">
              <span v-b-tooltip.hover title="entity excluded from pipeline"><i class="fas fa-times-circle"></i></span>
            </div>
            <div class="entityName">
              <div class="displayName">
                {{ getEntityName(entity) }}
                <span>
                  <b-button
                    :id="`${getDynamicTag('aliasCollapse', getEntityId(entity), articleIndex, currentPipeline)}`"
                    type="button"
                    data-placement="auto"
                    class="btn btn-company-info"
                  >
                    <i class="fas fa-info-circle"></i>
                  </b-button>
                  <b-popover
                    triggers="click blur"
                    :target="getDynamicTag('aliasCollapse', getEntityId(entity), articleIndex, currentPipeline)"
                  >
                    <span class="noSelect">
                      <u>{{ getEntityTypeLabel(currentPipeline) }}</u
                      ><buttonCopy :value="getEntityId(entity)" title="Copied company id"
                    /></span>
                    <div v-if="entity.primaryInstrumentTvlId" class="mb-1">
                      <span class="noSelect">
                        <u>primaryInstrumentId</u>
                        <buttonCopy :value="entity.primaryInstrumentTvlId" title="Copied primaryInstrumentId"
                      /></span>
                    </div>
                    <div v-if="entity.symbol" class="mb-1">
                      <u>Ticker</u> <span class="symbol"> {{ entity.symbol }} </span>
                    </div>
                    <ul class="aliasList">
                      <li
                        v-for="(alias, aliasIndex) in getAliases(entity.internalAliases)"
                        :key="`${currentPipeline}_${articleIndex}_${alias}_${aliasIndex}`"
                        class="alias"
                      >
                        {{ alias }}
                      </li>
                    </ul>
                  </b-popover>
                </span>
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-for="lens in lenses">
          <tr v-if="_.size(categories[lens.key])" :key="lens.key">
            <td class="lens-label" :class="lens.key" :colspan="localEntities.length">
              {{ _.toUpper(lens.key) }} {{ currentPipeline }}
            </td>
          </tr>
          <template v-for="category in categories[lens.key]">
            <tr v-if="lens.key !== 'tvl'" :key="`catLableRow_${articleIndex}_${lens.key}_${category}`">
              <td class="cat-label" :class="lens.key" :colspan="localEntities.length">{{ category }}</td>
            </tr>
            <tr v-if="lens.key !== 'tvl'" :key="`catScoreRow_${articleIndex}_${lens.key}_${category}`">
              <td
                v-for="entity in localEntities"
                :key="`catScoreCell_${articleIndex}_${lens.key}_${category}_${entity.tvlId}`"
                class="catScore entityCategory px-1"
                :class="currentPipeline"
              >
                <polarityCatScore
                  :entity="entity"
                  :category="category"
                  :lens="lens.key"
                  :current-pipeline="currentPipeline"
                  :article-id="article._id"
                  :original-score="getOriginalScore(lens.key, entity, category)"
                />
              </td>
            </tr>
          </template>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import xentities from '../../js/xentities'
import xentitiesComputed from '../../js/xentitiesComputed'
import xconfig from '../../js/xconfig'
import buttonCopy from '../buttons/buttonCopy'
import polarityCatScore from '../entites/polarityCatScore'
import ButtonCopy from '../buttons/buttonCopy.vue'

const { pipelines } = xconfig

export default {
  name: 'EntityPolarity',
  components: {
    buttonCopy,
    polarityCatScore,
    ButtonCopy
  },
  filters: {
    round: function (value) {
      if (typeof value !== 'number') return value
      return Math.round(value)
    }
  },
  props: {
    article: {
      type: Object,
      default: null
    },
    articleIndex: {
      type: Number,
      default: null
    },
    currentPipeline: {
      type: String,
      default: null
    }
  },
  computed: {
    ...xentitiesComputed,
    ...mapGetters([
      'articles',
      'articleFilters',
      'curatingFrom',
      'collectionType',
      'collectionName',
      'polarityChanges'
    ]),
    approvedEntitiesOnly() {
      const entities = _.filter(_.get(this.article, pipelines.paths[this.currentPipeline].tag, []), ['excluded', false])
      return entities
    },
    originalEntities() {
      const originalEntities = _.get(
        this.article,
        `originalPolarityTags.${pipelines.paths[this.currentPipeline].tag}`,
        []
      )
      return originalEntities
    },
    localEntities() {
      let entities = this.approvedEntitiesOnly
      let originalEntities = this.originalEntities
      const enitityList = _.map(entities, 'tvlId')
      const oEnitityList = _.map(originalEntities, 'tvlId')
      const differences = _.xor(enitityList, oEnitityList)
      if (_.size(originalEntities) && _.size(differences)) {
        const clonedOriginal = _.cloneDeep(originalEntities)
        _.each(clonedOriginal, (entity, entityIndex) => {
          const currentEntity = _.find(entities, ['tvlId', entity.tvlId])
          if (!_.isEmpty(currentEntity)) {
            clonedOriginal[entityIndex] = currentEntity
          } else {
            const catKey = this.currentPipeline === 'v2' ? 'categories' : 'scores'
            _.each(_.get(entity, catKey), (lens, lensId) => {
              entity.excluded = true
              _.each(lens, (cat, catId) => {
                _.set(entity, [catKey, lensId, catId], undefined)
              })
            })
          }
        })
        entities = clonedOriginal
      }
      return entities
    },
    categories() {
      const self = this
      const allCategories = _.reduce(
        this.lenses,
        function (acc, lens) {
          const categories = _.reduce(
            self.localEntities,
            function (cats, entity) {
              const thisCats = _.map(_.get(entity, self.lensKey(lens.key), {}), function (value, key) {
                return key
              })

              let originalCats = []
              if (self.article.originalPolarityTags) {
                const originalTagPath = `dp${self.currentPipeline}.associationTags`
                const orginalEntity = _.find(_.get(self.article.originalPolarityTags, originalTagPath), {
                  tvlId: entity.tvlId
                })
                originalCats = _.map(_.get(orginalEntity, self.lensKey(lens.key), {}), function (value, key) {
                  return key
                })
              }

              cats = _.union(cats, originalCats)
              cats = _.union(cats, thisCats)
              return cats
            },
            []
          )
          acc[lens.key] = categories
          return acc
        },
        {}
      )
      return allCategories
    },
    polarityLevels() {
      const levels = _.map(xconfig.polarityScoreLevels, (polarityLevel) => {
        return { value: polarityLevel.level, text: polarityLevel.level.toString() }
      })
      return levels
    },
    pipelines() {
      return pipelines
    }
  },
  methods: {
    ...xentities,
    lensKey(lens) {
      return this.currentPipeline === 'v2' ? `categories.${_.toUpper(lens)}scores` : `scores.${lens}`
    },
    getOriginalScore(lens, entity, category) {
      const entityPath = `dp${this.currentPipeline}.associationTags`
      const polarityEntityPath = `originalPolarityTags.${entityPath}`
      const initialEntity = _.find(_.get(this.article, entityPath), { tvlId: entity.tvlId })
      const originalPolarityEntity = _.find(_.get(this.article, polarityEntityPath), { tvlId: entity.tvlId })
      const scorePath = `${this.lensKey(lens)}.${category}`
      const originalEntity = !_.isEmpty(originalPolarityEntity) ? originalPolarityEntity : initialEntity
      const originalScore = _.get(originalEntity, scorePath)
      return originalScore
    }
  }
}
</script>
