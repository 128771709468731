<template>
  <div id="app">
    <NavHeader v-if="'login' !== $route.name" :is-site-header="true" />
    <RouterView ref="baseComponentRef" />
    <RwvFooter ref="footerComponentRef" />
    <div id="page-spinner" class="container-fluid">
      <div class="col screen-centered"><div class="spinner" /></div>
    </div>
    <input ref="copyTextContainer" class="d-none" type="text" />
  </div>
</template>

<script>
import xlibs from './js/xlibs'
import xcomputed from './js/xcomputed'
import NavHeader from './components/navHeader'
import RwvFooter from './components/footer'

const { initDefaultVm } = xlibs

export default {
  name: 'App',
  components: {
    NavHeader,
    RwvFooter
  },
  computed: {
    ...xcomputed
  },
  mounted() {
    initDefaultVm(this)
    if (!this.autoloadTimeoutId && !this.featureSwitches.disableArticleRecurationLock) {
      this.autoloadTimeoutId
      this.scheduleNextRefresh()
    }
  },
  destroyed() {
    if (this.autoloadTimeoutId) {
      clearTimeout(this.autoloadTimeoutId)
      this.autoloadTimeoutId = false
    }
  },
  methods: {
    ...xlibs
  },
  sockets: {
    connect: function () {
      xlibs.initSocket(this)
    },
    authenticate: function (message) {
      console.debug('socket authenticate:', message)
    },
    disconnect() {
      console.debug('server disconnected')
    }
  }
}
</script>

<style lang="scss">
@import './scss/xman.scss';
</style>
