<template>
  <div
    id="articleExcludeModal"
    class="modal fade bd-example-modal-lg"
    :tabindex="tabindex"
    role="dialog"
    aria-labelledby="myLargeModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-md">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Reason for exclusion</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true"> &times; </span>
          </button>
        </div>
        <div class="modal-body">
          <div id="exclude-article-pop-up-content">
            <form id="excludeArticleForm" name="articleFlagForm" @submit.prevent="_.noop">
              <div class="exclude-article-reasons align-left">
                <div class="exclude-article-title"><b>Article:</b> {{ excludeModalArticle.title }}</div>
                <div v-for="option in exclusionOptions" :key="option.key" class="exclusionOption">
                  <h5 class="align-left">{{ option.text }}</h5>

                  <div v-for="selection in option.selections" :key="selection.key" class="selections">
                    <input
                      :id="selection.key"
                      v-model="exclusionReasons[selection.key]"
                      type="checkbox"
                      :class="{ onpage: tabindex === 0 }"
                    /><label :for="selection.key">
                      {{ selection.text }}
                    </label>
                  </div>
                </div>

                <h5 class="align-left">Other</h5>
                <div>
                  <input
                    id="other"
                    v-model="exclusionReasons.other"
                    type="checkbox"
                    :class="{ onpage: tabindex === 0 }"
                  /><label for="other">
                    <input
                      v-model="otherReason"
                      placeholder="reason"
                      maxlength="100"
                      size="60"
                      :class="{ onpage: tabindex === 0 }"
                      @input="otherInput"
                    />
                  </label>
                </div>
                <div class="exclusion-submit text-center">
                  <button
                    type="button"
                    class="btn-sm btn-purple"
                    :class="{ onpage: tabindex === 0 }"
                    @click="excludeArticle"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import xlibs from '../js/xlibs'
import xconfig from '../js/xconfig'

export default {
  name: 'ArticleExcludeModal',
  props: {},
  data() {
    return {
      exclusionOptions: xconfig.exclusionOptions,
      otherReason: '',
      tabindex: -1,
      exclusionReasons: {}
    }
  },
  computed: {
    ...mapGetters([
      'articles',
      'count',
      'excludeModalArticle',
      'articleFilters',
      'curatingFrom',
      'collectionType',
      'collectionName'
    ])
  },
  mounted() {
    const self = this
    $('#articleExcludeModal').on('shown.bs.modal', function () {
      self.otherReason = ''
      if (self.excludeModalArticle.exclusionReasons) {
        self.exclusionReasons = _.reduce(
          self.excludeModalArticle.exclusionReasons,
          (acc, reason) => {
            if (reason.other) {
              acc.other = true
              self.otherReason = reason.other
            } else {
              acc[reason] = true
            }
            return acc
          },
          {}
        )
      }
      self.tabindex = 0
      $('#companyIsntMentionedInTheArticleAtAll').focus()
    })
    $('#articleExcludeModal').on('hide.bs.modal', function () {
      self.clearExcludeModal()
      self.tabindex = -1
    })
  },
  destroyed() {
    $('#articleExcludeModal').off('shown.bs.modal')
    $('#articleExcludeModal').off('hidden.bs.modal')
  },
  methods: {
    showMessageAlert: xlibs.showMessageAlert,
    otherInput() {
      const self = this
      self.exclusionReasons.other = !!_.trim(self.otherReason).length
    },
    clearExcludeModal() {
      this.exclusionReasons = {}
      this.otherReason = ''
      this.$store.dispatch('setExcludeModalArticle', {})
    },
    async excludeArticle() {
      const self = this
      const reasons = []
      _.each(self.exclusionReasons, function (value, key) {
        if (value) {
          if (key === 'other') {
            if (_.trim(self.otherReason)) {
              key = { other: _.trim(self.otherReason) }
            } else {
              key = false
            }
          }
          if (key) {
            reasons.push(key)
          }
        }
      })
      console.log('reasons', reasons)
      if (reasons.length < 1) {
        return
      }
      console.log('curatingFrom', self.curatingFrom)
      const curationRequestType = xlibs.getCurationRequestType(self.curatingFrom)
      self.excludeModalArticle.exclusionReasons = reasons
      console.log('excludeArticle', self.excludeModalArticle)
      self.otherReason = ''
      const $articleNode = $(`#article-${self.excludeModalArticle._id}`)
      $articleNode.addClass('removing')
      setTimeout(function () {
        $articleNode.removeClass('removing')
      }, 300)
      const baseUrl = xlibs.getArticlesBaseUrl(self)
      const url = `${baseUrl}/article/exclude/${self.excludeModalArticle._id}`
      const postPayload = {
        exclusionReasons: self.excludeModalArticle.exclusionReasons,
        curatingFrom: curationRequestType
      }
      xlibs.showSearchSpinner()
      const { data } = await axios
        .post(url, postPayload)
        .catch((error) => {
          xlibs.requestFailure(error)
        })
        .finally(() => {
          xlibs.showSearchSpinner(false)
        })
      console.log('Data Loaded:', data)
      let alertMessage
      if (data.alreadyProcessed) {
        alertMessage = `Article Already Processed: ${self.excludeModalArticle._id}`
      } else if (data.ingested) {
        xlibs.submitCurationSocket(self, self.excludeModalArticle._id, 'ingested')
        alertMessage = `<b>Approved</b> Article: ${self.excludeModalArticle._id}`
      } else if (data.excluded) {
        xlibs.submitCurationSocket(self, self.excludeModalArticle._id, 'excluded')
        alertMessage = `<b>Excluded</b> Article: ${self.excludeModalArticle._id}`
      }

      if (alertMessage) {
        xlibs.showMessageAlert(alertMessage)
        if (self.articleFilters.page && self.articles.length > _.get(self, 'articleFilters.count.batchSize', 10)) {
          // if not on first page, only remove the article to preserve article list
          self.$store.dispatch('removeArticle', self.excludeModalArticle._id)
        } else {
          xlibs.applyFilters.call(self.$parent)
        }
        // todo: use events to update curationstats
        if (_.has(self, '$parent.$refs.curationStats.getCurationStats')) {
          self.$parent.$refs.curationStats.getCurationStats()
        }
      } else {
        xlibs.showErrorAlert(`unknown error excluding article: ${self.excludeModalArticle._id}`)
      }
      $('#articleExcludeModal').modal('hide')
    }
  }
}
</script>
