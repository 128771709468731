var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a',{directives:[{name:"inview",rawName:"v-inview:class",value:({ onpage: true }),expression:"{ onpage: true }",arg:"class"},{name:"inview",rawName:"v-inview:class.leave",value:({ onpage: false }),expression:"{ onpage: false }",arg:"class",modifiers:{"leave":true}}],ref:"approveBtn",staticClass:"approve",class:{
    disabled:
      _vm.curationChangeWindowExpired ||
      _vm.article.approveDisabled ||
      !_vm._.isEmpty(_vm.article.curationDisabled) ||
      _vm.featureSwitches.disableArticleCuration,
    curationExpired: _vm.curationChangeWindowExpired
  },attrs:{"tabindex":_vm.article.approveDisabled || !_vm._.isEmpty(_vm.article.curationDisabled) ? -1 : 0,"title":"Approve Selected","href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.approveArticle(_vm.article._id)}}},[(_vm.curationChangeWindowExpired)?_c('span',{staticClass:"curationChangeWindowExpired"},[_c('i',{staticClass:"fa fa-icicles"}),_vm._v(" "),_c('i',{staticClass:"fa fa-check-circle"})]):_vm._e(),_vm._v(" "),(!_vm.curationChangeWindowExpired)?_c('span',[(_vm.subjectToChangeWindow)?_c('span',{staticClass:"subjectToChangeWindow"},[_c('i',{staticClass:"fa fa-clock expire-warning"}),_vm._v(" "),_c('i',{staticClass:"fa fa-check-circle"})]):_vm._e(),_vm._v(" "),(!_vm.subjectToChangeWindow)?_c('span',[_c('i',{staticClass:"fa fa-check-circle"})]):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }