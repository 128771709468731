<template>
  <div class="incoming-page main-template">
    <div class="position-absolute statsBox">
      <curationStats ref="curationStats" />
      <subNav />
    </div>
    <div class="container-fluid form-controls">
      <controlLanguageFilter />
      <controlCompanyReporting />
      <div class="form-row">
        <controlCompanyFilter />
      </div>
      <controlDateFilter />
      <div v-if="isAdmin || isStatic" class="form-row documentHashControl">
        <controlDocumentHashFilter />
      </div>
      <div v-if="environment !== 'production' && isAdmin">
        <controlLimitFilter />
      </div>
    </div>
    <div class="container-fluid count">
      showing {{ count.currentCount }} of {{ count.totalCount }}
      {{ count.totalCount | pluralize('article') }}
      <span v-if="isAdmin && count.currentCount && !featureSwitches.disableArticleCuration" class="bulkApprove">
        <button
          id="approve-bulk"
          class="btn btn-sm btn-primary"
          type="button"
          tabindex="-1"
          :disabled="!count.currentCount"
          @click="bulkConfirmation"
        >
          Approve {{ count.currentCount }} Displayed
          {{ count.currentCount | pluralize('Article') }}
        </button>
      </span>
    </div>
    <div id="search-spinner" class="container-fluid">
      <div class="col screen-centered"><div class="spinner" /></div>
    </div>
    <div v-if="articles" class="container-fluid page articles">
      <div class="request-overlay"></div>
      <div class="row article-header sticky-top">
        <div class="col col-sm-2 curationSection">
          <div class="row curationRow">
            <div class="col col-sm-4 actionCol">Curation</div>
            <sortDateTableHeader container-class="col-sm-8 pubDateCol" date-type="articlePubDate" />
          </div>
        </div>
        <div class="col articleDetailsSection" :class="detailsGridClass">
          <div class="row articlesRow">
            <localSortTableHeader container-class="titleCol float-left my-col" field-type="headline" />
            <localSortTableHeader container-class="sourceCol float-right col-3 text-right" field-type="source" />
          </div>
        </div>
        <div class="col entitiesSection" :class="entitiesGridClass"><entities-table-header /></div>
      </div>
      <div
        v-for="(article, articleIndex) in articles"
        :id="'article-' + article._id"
        :key="article._id"
        v-inview:class="{ onpage: true }"
        v-inview:class.leave="{ onpage: false }"
        class="row article"
        :article="article"
        :class="{ disabled: !_.isEmpty(article.curationDisabled) }"
      >
        <curation-disabled-note :curation-disabled="article.curationDisabled" @resetPage="resetPage" />
        <div class="col col-sm-2 curationSection">
          <div class="row">
            <div class="col col-sm-4 actionCol text-center">
              <approveButton ref="initialBtn" :article="article" :article-index="articleIndex" />
              <excludeButton :article="article" :article-index="articleIndex" />
            </div>
            <div class="col col-sm-8 pubDateCol">
              <articleDateInfo :article="article" :show-polarity-review="true" />
            </div>
          </div>
        </div>

        <div class="col articleDetailsSection" :class="detailsGridClass">
          <div class="row">
            <div class="col article-body">
              <div class="titleCol">
                <a
                  data-toggle="modal"
                  data-target="#articleModal"
                  tabindex="-1"
                  href="#"
                  @click="openArticle(article)"
                  >{{ getTitle(article) }}</a
                >
              </div>
              <hr />
              <div class="sourceCol float-right rounded border border-info p-1">
                <div class="source">{{ _.get(article, 'source.name', article.source) }}</div>
                <div class="sourceType">{{ article.sourceType }}</div>
                <div class="humanLanguage badge badge-info text-uppercase">
                  {{ getLanguage(article.humanLanguage) }}
                </div>
                <country-flag :country="getFlag(article)" size="big" class="language-flag" />
              </div>
              <span v-dompurify-html="highlightSummary(article)" class="summaryCol"> </span>
            </div>
          </div>
        </div>
        <div class="col entitiesSection" :class="entitiesGridClass">
          <entitiesSection :article="article" :article-index="articleIndex" />
        </div>
      </div>
      <div v-if="_.get(articles, 'length') === 0" id="noArticles" class="row">
        <div class="col screen-centered"><b>No Articles Found</b></div>
      </div>
    </div>
    <articleModal />
    <articleExcludeModal @update-articles="updateArticles" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import approveButton from '../components/buttons/approveButton'
import excludeButton from '../components/buttons/excludeButton'
import curationStats from '../components/curationStats'
import controlCompanyFilter from '../components/formControls/controlCompanyFilter'
import controlCompanyReporting from '../components/formControls/controlCompanyReporting'
import controlDateFilter from '../components/formControls/controlDateFilter'
import controlDocumentHashFilter from '../components/formControls/controlDocumentHashFilter'
import controlLanguageFilter from '../components/formControls/controlLanguageFilter'
import controlLimitFilter from '../components/formControls/controlLimitFilter'
import curationDisabledNote from '../components/curationDisabledNote'
import entitiesSection from '../components/entites/entitiesSection'
import sortDateTableHeader from '../components/sortDateTableHeader'
import localSortTableHeader from '../components/localSortTableHeader'
import entitiesTableHeader from '../components/entites/entitiesTableHeader'
import articleModal from '../components/articleModal'
import articleExcludeModal from '../components/articleExcludeModal'
import articleDateInfo from '../components/articleDateInfo'
import xlibs from '../js/xlibs'
import xcomputed from '../js/xcomputed'
import xentitiesComputed from '../js/xentitiesComputed'
import subNav from '../components/subNav'

export default {
  name: 'Incoming',
  components: {
    approveButton,
    excludeButton,
    curationStats,
    articleDateInfo,
    controlCompanyFilter,
    controlCompanyReporting,
    controlDateFilter,
    controlDocumentHashFilter,
    controlLanguageFilter,
    controlLimitFilter,
    curationDisabledNote,
    entitiesSection,
    sortDateTableHeader,
    localSortTableHeader,
    entitiesTableHeader,
    articleModal,
    articleExcludeModal,
    subNav
  },
  filters: {},
  props: [],

  data() {
    return {
      currentUser: {}
    }
  },
  computed: {
    ...xcomputed,
    ...xentitiesComputed,
    ...mapGetters([
      'articles',
      'count',
      'modalArticle',
      'articleFilters',
      'collectionType',
      'collectionName',
      'expandedScores'
    ])
  },
  mounted() {
    this.currentUser = xlibs.getUser()
  },
  created() {
    window.addEventListener('keydown', this.keyNavigation)
    this.$store.dispatch('resetArticleFilters', 'incoming')
    this.setCuratingFrom(this.curatingFrom)
    this.resetPage()
  },
  destroyed() {
    window.removeEventListener('keydown', this.keyNavigation)
  },
  methods: {
    ...xlibs,
    async resetPage() {
      // dropdown defaults
      await this.clearStores()
      // this.sort = 1
      await this.applyFilters()
      const initialFocus = $(_.get(this, '$refs.initialBtn[0].$el', {}))
      initialFocus.focus()
    },
    highlightSummary(article) {
      return xlibs.highlightSummary(article)
    },
    async updateArticles(articles, totalCount) {
      this.$store.dispatch('setArticles', articles)
      this.$store.dispatch('updateCount', totalCount)
    },
    approveBulk() {
      let approvedCounter = 0
      _.each(this.articles, (article) => {
        if (_.isEmpty(article.curationDisabled)) {
          this.approveArticle(article._id)
          approvedCounter += 1
        }
      })
      if (!approvedCounter) {
        this.resetPage()
      }
    },
    bulkConfirmation() {
      // if (this.environment === 'development') {
      //   return this.approveBulk()
      // }
      const title =
        this.environment === 'production' ? 'PRODUCTION WARNING: Confirm Bulk Approval' : 'Confirm Bulk Approval'
      const okVariant = this.environment === 'production' ? 'danger' : 'warning'
      this.$bvModal
        .msgBoxConfirm('Bulk approve the displayed articles?', {
          title,
          size: 'sm',
          buttonSize: 'sm',
          okVariant,
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
        .then((accepted) => {
          if (accepted) {
            this.approveBulk()
          }
        })
    }
  },
  sockets: {
    xmanCurationSocket: xlibs.xmanCurationSocket
  }
}
</script>
<style></style>
