<template>
  <div class="col-5 border border-dark p-1 rounded bg-light">
    <b-row class="m-0 p-0">
      <b-col class="col-sm-auto m-0 p-0">
        <label for="input-documentHash"
          ><span id="dochashTooltip" :class="{ bold: validation }">DocumentHash*</span>:</label
        >
        <b-tooltip target="dochashTooltip" triggers="hover"> Overrides all search fields </b-tooltip>
      </b-col>
      <b-col class="col-sm-auto">
        <b-form-input
          id="input-documentHash"
          ref="documentHash"
          v-model="localDocumentHash"
          type="search"
          placeholder="Enter DocumentHash"
          trim
          debounce="500"
          tabindex="-1"
          size="sm"
          :maxlength="docHashLength"
          :state="validation"
        ></b-form-input>
        <b-form-invalid-feedback :state="validation">
          Must be {{ docHashLength }} characters long.
        </b-form-invalid-feedback>
      </b-col>
    </b-row>
    <b-row class="m-0 p-0">
      <b-col class="col-sm-auto m-0 p-0">
        <b v-show="validation">Overrides all other search fields</b>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import xlibs from '../../js/xlibs'
import xcomputed from '../../js/xcomputed'
import xconfig from '../../js/xconfig'

export default {
  name: 'ControlDocumentHashFilter',
  props: [],
  computed: {
    isAdmin: xcomputed.isAdmin,
    isLive: xcomputed.isLive,
    isStatic: xcomputed.isStatic,
    ...mapGetters(['articles', 'articleFilters', 'curatingFrom', 'collectionType', 'collectionName']),
    ...mapState({
      documentHash: (state) => _.get(state, 'articleFilters.documentHash')
    }),
    localDocumentHash: {
      get: function () {
        return this.documentHash
      },
      set: function (newValue) {
        this.updateFormFilter(newValue)
      }
    },
    docHashLength: () => xconfig.formsConfig.documentHash.length,
    validation() {
      const validCheck = _.size(this.localDocumentHash) == this.docHashLength
      const valid = !_.size(this.localDocumentHash) ? null : validCheck
      return valid
    },
    isValid() {
      return this.validation === null || this.validation
    }
  },
  methods: {
    async updateFormFilter(formText) {
      await this.$store.dispatch('setArticleFilter', { documentHash: formText })
      if (this.isValid) {
        await xlibs.applyFilters.apply(this)
      }
      if (!_.isEmpty(formText)) this.$refs.documentHash.$el.focus()
    }
  }
}
</script>
