<template>
  <div class="form-row date-row">
    <div class="col-auto my-3 mx-2">
      Start Date (UTC):
      <input id="startDate-filter" :value="startDate" type="text" class="form-control-sm" width="200" tabindex="-1" />
    </div>
    <div class="col-auto my-3 mx-2">
      End Date (UTC):
      <input id="endDate-filter" :value="endDate" type="text" class="form-control-sm" width="200" tabindex="-1" />
    </div>
    <controlDateType />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import controlDateType from './controlDateType'
import xlibs from '../../js/xlibs'
import xconfig from '../../js/xconfig'
import xcomputed from '../../js/xcomputed'

export default {
  name: 'ControlDateFilter',
  components: {
    controlDateType
  },
  props: {
    subtractDaysFromStart: {
      type: Number,
      default: null
    }
  },
  computed: {
    isAdmin: xcomputed.isAdmin,
    isLive: xcomputed.isLive,
    isStatic: xcomputed.isStatic,
    ...mapGetters(['articleFilters', 'curatingFrom', 'collectionType', 'collectionName']),
    ...mapState({
      startDate: (state) => state.articleFilters.startDate,
      endDate: (state) => state.articleFilters.endDate,
      reportDetailType: (state) => state.articleFilters.reportDetailType
    })
  },
  mounted() {
    this.resetDates()
  },
  methods: {
    resetDates() {
      let customStartDate, startFormat
      if (!this.subtractDaysFromStart) {
        startFormat = xconfig.dateFormat
        customStartDate = this.isStatic ? xconfig.defaultStartDateStatic : xconfig.defaultStartDate
      }
      const startDefault = moment
        .utc(customStartDate, startFormat)
        .subtract(this.subtractDaysFromStart, 'days')
        .format(xconfig.dateFormat)
      const endDefault = moment().utc().format(xconfig.dateFormat)
      let minDefault = moment().utc().subtract(3, 'months').format(xconfig.dateFormat)
      if (moment(startDefault, xconfig.dateFormat) < moment(minDefault, xconfig.dateFormat)) {
        minDefault = startDefault
      }

      window.$datepickerStart = $('#startDate-filter').datepicker({
        keyboardNavigation: false,
        uiLibrary: 'bootstrap4',
        value: startDefault,
        maxDate: endDefault,
        change: this.updateStartDate
      })
      window.$datepickerEnd = $('#endDate-filter').datepicker({
        keyboardNavigation: false,
        uiLibrary: 'bootstrap4',
        value: endDefault,
        maxDate: endDefault,
        change: this.updateEndDate
      })
      this.$store.dispatch('setArticleFilter', { startDate: startDefault })
      this.$store.dispatch('setArticleFilter', { endDate: endDefault })

      $('div.gj-datepicker button.btn').attr('tabindex', -1)
    },
    validateMaxDayRange(startDate, endDate) {
      if (
        this.maxDayRange &&
        moment(endDate, xconfig.dateFormat).diff(moment(startDate, xconfig.dateFormat), 'days') > this.maxDayRange
      ) {
        window.$datepickerEnd.value(
          moment()
            .utc(this.articleFilters.startDate, xconfig.dateFormat)
            .add(this.maxDayRange, 'days')
            .format(xconfig.dateFormat)
        )
        xlibs.showErrorAlert(`maximun date range is ${this.maxDayRange} day${this.maxDayRange > 1 ? 's' : ''}`)
      }
    },
    updateStartDate(event) {
      const newStartDate = event.target.value
      if (newStartDate === this.articleFilters.startDate) return
      if (moment(this.articleFilters.endDate, xconfig.dateFormat) >= moment(newStartDate, xconfig.dateFormat)) {
        this.validateMaxDayRange(newStartDate, this.articleFilters.endDate)
        this.$store.dispatch('setArticleFilter', { startDate: newStartDate })
        this.$emit('preapply')
        if (this.reportDetailType !== xconfig.reportDetailTypes.summary) {
          xlibs.applyFilters.call(this)
        }
      } else {
        window.$datepickerStart.value(this.articleFilters.startDate)
        xlibs.showErrorAlert('start date must be before end date')
      }
    },
    updateEndDate(event) {
      const newEndDate = event.target.value
      if (newEndDate === this.articleFilters.endDate) return
      if (moment(this.articleFilters.startDate, xconfig.dateFormat) <= moment(newEndDate, xconfig.dateFormat)) {
        this.validateMaxDayRange(this.articleFilters.startDate, newEndDate)
        this.$store.dispatch('setArticleFilter', { endDate: newEndDate })
        this.$emit('preapply')
        if (this.reportDetailType !== xconfig.reportDetailTypes.summary) {
          xlibs.applyFilters.call(this)
        }
      } else {
        window.$datepickerEnd.value(this.articleFilters.endDate)
        xlibs.showErrorAlert('start date must be before end date')
      }
    }
  }
}
</script>
