<template>
  <div class="col-auto m-1">
    <b-row class="m-0 p-0">
      <b-col class="col-sm-auto m-0 p-0">
        <label for="input-companyId">Company:</label>
      </b-col>
      <b-col class="col-sm-auto">
        <b-form-input
          id="input-companyId"
          ref="companyId"
          v-model="localCompanyId"
          type="search"
          placeholder="Enter orgTvlId"
          trim
          debounce="500"
          tabindex="-1"
          size="sm"
          :maxlength="maxLength"
          :state="validation"
        ></b-form-input>
        <b-form-invalid-feedback :state="validation">
          Must be either {{ minLength }} or {{ maxLength }} characters long.
        </b-form-invalid-feedback>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import xlibs from '../../js/xlibs'
import xcomputed from '../../js/xcomputed'
import xconfig from '../../js/xconfig'

export default {
  name: 'ControlCompanyFilter',
  props: [],
  computed: {
    isAdmin: xcomputed.isAdmin,
    isLive: xcomputed.isLive,
    isStatic: xcomputed.isStatic,
    ...mapGetters(['articles', 'articleFilters', 'curatingFrom', 'collectionType', 'collectionName']),
    ...mapState({
      isinFilter: (state) => _.get(state, 'articleFilters.isinFilter')
    }),
    localCompanyId: {
      get: function () {
        return this.isinFilter
      },
      set: function (newValue) {
        this.updateCompanyFilter(newValue)
      }
    },
    minLength: () => xconfig.formsConfig.companyId.minLength,
    maxLength: () => xconfig.formsConfig.companyId.maxLength,
    validation() {
      const validCheck = _.size(this.localCompanyId) == this.minLength || _.size(this.localCompanyId) == this.maxLength
      const valid = !_.size(this.localCompanyId) ? null : validCheck
      return valid
    },
    isValid() {
      return this.validation === null || this.validation
    }
  },
  methods: {
    async updateCompanyFilter(value) {
      const companyText = value.substring(0, this.maxLength)
      await this.$store.dispatch('setArticleFilter', { isinFilter: companyText })
      if (this.isValid) {
        await xlibs.applyFilters.apply(this)
      }
      if (!_.isEmpty(companyText)) this.$refs.companyId.$el.focus()
    }
  }
}
</script>
