<template>
  <div class="entities" :class="currentPipeline">
    <div
      v-for="(entity, entityIndex) in localEntities"
      :key="entity._id"
      class="row entitiesRow"
      :class="currentPipeline"
    >
      <div class="col entityName custom-control custom-checkbox" :class="entityNameGridClass">
        <span class="version badge" :class="getBadgeClass()">{{ currentPipeline }}</span>
        <b-form-checkbox
          v-model="entity.excluded"
          v-inview:class="{ onpage: true }"
          v-inview:class.leave="{ onpage: false }"
          :indeterminate="doesExcludeHaveValue(entity)"
          value="false"
          unchecked-value="true"
          @change="checkEntity(entityIndex, entity.tvlId, currentPipeline)"
        >
        </b-form-checkbox>

        <div class="displayName">{{ getEntityName(entity) }}</div>
        <hr />
        <span class="noSelect">
          {{ getEntityTypeLabel(currentPipeline) }}:<buttonCopy :value="getEntityId(entity)" title="Copied company id"
        /></span>
        <div v-if="entity.primaryInstrumentTvlId" class="mb-1">
          <span class="noSelect">
            primaryInstrumentId:
            <buttonCopy :value="entity.primaryInstrumentTvlId" title="Copied primaryInstrumentId"
          /></span>
        </div>
        <div v-if="entity.symbol" class="mb-1">
          Ticker: <span class="symbol"> {{ entity.symbol }} </span>
        </div>
        <span v-if="hasAliases(entity.internalAliases)">
          <a
            class="btn-sm btn-primary btn-aliases"
            data-toggle="collapse"
            :href="getDynamicTag('#aliasCollapse', getEntityId(entity), articleIndex, currentPipeline)"
            role="button"
            tabindex="-1"
            @click="toggleLabel"
          >
            <span>Show</span> Aliases
          </a>
          <div
            :id="getDynamicTag('aliasCollapse', getEntityId(entity), articleIndex, currentPipeline)"
            class="collapse"
          >
            <ul class="aliasList">
              <li
                v-for="(alias, aliasIndex) in getAliases(entity.internalAliases)"
                :key="`${currentPipeline}_${articleIndex}_${alias}_${aliasIndex}`"
                class="alias"
              >
                {{ alias }}
              </li>
            </ul>
          </div>
        </span>
      </div>
      <div class="col scores-section" :class="[currentPipeline, scoresSectionGridClass]">
        <div class="row h-100">
          <div class="col sasb-scores scores" :class="[currentPipeline, scoreSizing.classes.sasb.sizeClass]">
            <div v-for="(score, key) in getScores(entity, article, 'sasb')" :key="key" class="entityCategory row">
              <span
                class="categoryKey col"
                :title="key"
                :class="getScorePolarityClass(score)"
                @click.prevent="toggleExpand('sasb')"
                >{{ key }}</span
              ><span class="categoryScore col" :class="getScorePolarityClass(score)">{{ score }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import xentities from '../../js/xentities'
import xentitiesComputed from '../../js/xentitiesComputed'
import xconfig from '../../js/xconfig'
import buttonCopy from '../buttons/buttonCopy'

const { pipelines } = xconfig

export default {
  name: 'EntityDetails',
  components: {
    buttonCopy
  },
  props: {
    article: {
      type: Object,
      default: null
    },
    articleIndex: {
      type: Number,
      default: null
    },
    currentPipeline: {
      type: String,
      default: null
    }
  },
  computed: {
    ...xentitiesComputed,
    ...mapGetters(['expandedScores']),
    localEntities() {
      const entities = _.cloneDeep(_.get(this.article, pipelines.paths[this.currentPipeline].tag, []))
      return entities
    },
    pipelines() {
      return pipelines
    }
  },
  methods: {
    ...xentities,
    doesExcludeHaveValue(enitiy) {
      return _.isNil(enitiy.excluded)
    },
    toggleExpand(key) {
      this.$store.dispatch('toggleExpandedScore', key)
    }
  }
}
</script>
