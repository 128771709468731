<template>
  <div style="margin-top: 10px" class="form-row">
    <div class="col-auto m-1">
      Curator:
      <select
        id="user-filter"
        v-model="user"
        name="user-filter"
        class="form-control-sm"
        tabindex="-1"
        @change="filterByUser()"
      >
        <option value="none">-- Filter by user --</option>
        <option v-for="userOption in users" :key="userOption._id" :value="userOption._id">
          {{ userOption.email }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import xlibs from '../../js/xlibs'
import xcomputed from '../../js/xcomputed'

export default {
  name: 'ControlUserFilter',
  props: {
    spotlightMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      users: [],
      articleUsers: [],
      spotlightUsers: [],
      user: 'none'
    }
  },
  computed: {
    isAdmin: xcomputed.isAdmin,
    isLive: xcomputed.isLive,
    isStatic: xcomputed.isStatic,
    ...mapGetters(['articles', 'articleFilters', 'curatingFrom', 'collectionType', 'collectionName'])
  },
  watch: {
    spotlightMode: function (spotlightModeStatus) {
      spotlightModeStatus ? this.getSpotlightCurators() : this.getArticleCurators()
    }
  },
  mounted() {
    this.spotlightMode ? this.getSpotlightCurators() : this.getArticleCurators()
  },
  methods: {
    async getArticleCurators() {
      if (!_.size(this.articleUsers)) {
        const url = this.isLive ? '/api/curationUsers' : `/api/static/${this.collectionName}/curationUsers`
        const curationRequestType = xlibs.getCurationRequestType(this.curatingFrom)
        const params = { curatingFrom: curationRequestType }
        const { data } = await axios.get(url, { params }).catch((error) => {
          xlibs.requestFailure(error)
        })
        this.articleUsers = data
      }
      this.users = this.articleUsers
    },
    async getSpotlightCurators() {
      if (!_.size(this.spotlightUsers)) {
        const url = '/api/curationSpotlightUsers'
        const params = { curatingFrom: this.curatingFrom }
        const { data } = await axios.get(url, { params }).catch((error) => {
          xlibs.requestFailure(error)
        })
        this.spotlightUsers = data
      }
      this.users = this.spotlightUsers
    },
    async filterByUser() {
      this.$store.dispatch('setArticleFilter', { user: this.user })
      xlibs.applyFilters.apply(this)
    }
  }
}
</script>
