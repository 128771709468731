<template>
  <div class="col m-1">
    <b-row class="m-0 p-0">
      <b-col class="col-sm-auto m-0 p-0">
        <label for="input-headline">Headline:</label>
      </b-col>
      <b-col class="col-sm-auto">
        <b-form-input
          id="input-headline"
          ref="headline"
          v-model="localHeadlineText"
          type="search"
          placeholder="Enter headline text"
          trim
          debounce="500"
          tabindex="-1"
          size="sm"
          :maxlength="maxLength"
          :state="validation"
        ></b-form-input>
        <b-form-invalid-feedback :state="validation">
          Text must be between {{ minLength }}-{{ maxLength }} characters long.
        </b-form-invalid-feedback>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import xlibs from '../../js/xlibs'
import xcomputed from '../../js/xcomputed'
import xconfig from '../../js/xconfig'

export default {
  name: 'ControlHeadlineFilter',
  props: [],
  computed: {
    isAdmin: xcomputed.isAdmin,
    isLive: xcomputed.isLive,
    isStatic: xcomputed.isStatic,
    ...mapGetters(['articles', 'articleFilters', 'curatingFrom', 'collectionType', 'collectionName']),
    ...mapState({
      headlineFilter: (state) => state.articleFilters.headlineFilter
    }),
    localHeadlineText: {
      get: function () {
        return this.headlineFilter
      },
      set: function (newValue) {
        this.updateHeadlineFilter(newValue)
      }
    },
    minLength: () => xconfig.formsConfig.headline.minLength,
    maxLength: () => xconfig.formsConfig.headline.maxLength,
    validation() {
      const validCheck =
        _.size(this.localHeadlineText) >= this.minLength && _.size(this.localHeadlineText) <= this.maxLength
      const valid = !_.size(this.localHeadlineText) ? null : validCheck
      return valid
    },
    isValid() {
      return this.validation === null || this.validation
    }
  },
  methods: {
    async updateHeadlineFilter(value) {
      const headline = value.substring(0, this.maxLength)
      await this.$store.dispatch('setArticleFilter', { headlineFilter: headline })
      if (this.isValid) {
        await xlibs.applyFilters.apply(this)
      }
      if (!_.isEmpty(headline)) this.$refs.headline.$el.focus()
    }
  }
}
</script>
