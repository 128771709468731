<template>
  <div class="container-fluid userCurationStats">
    <div class="row">
      <div class="userInfo col">
        <span v-if="isAdmin" class="admin-icon" title="admin"><i class="fas fa-bolt" /> </span>
        {{ userName }} (<i>{{ userEmailAddress }}</i
        >)
      </div>
    </div>
    <div v-if="isLive && (isEntityCurator || isSentimentCurator)" class="row">
      <div class="curationStats col">
        <span class="ingested">
          Ingested:
          <span class="badge-pill badge-success"> {{ ingestedCount }} </span>
        </span>
        <span class="excluded">
          Excluded:
          <span class="badge-pill badge-danger"> {{ excludedCount }} </span>
        </span>
      </div>
    </div>
    <div v-if="isLive && (isEntityCurator || isSentimentCurator)" class="row">
      <div class="curationStats col">
        count for
        <a href="https://time.is/UTC" target="new" tabindex="-1"> Today ({{ dateUTC }} UTC) </a>
      </div>
    </div>
  </div>
</template>
<script>
import xlibs from '../js/xlibs'
import xcomputed from '../js/xcomputed'

export default {
  name: 'CurationStats',
  data() {
    return {
      userName: '',
      userEmailAddress: '',
      excludedCount: '',
      ingestedCount: '',
      dateUTC: '',
      v2: '',
      v2Error: ''
    }
  },
  computed: {
    ...xcomputed
  },
  watch: {
    $route() {
      const self = this
      if (self.isLive) {
        self.getCurationStats()
      }
    }
  },
  mounted() {
    const user = xlibs.getUser()
    this.userName = user.name
    this.userEmailAddress = user.email
    if (this.isLive) {
      this.getCurationStats()
    }
  },
  methods: {
    async getCurationStats() {
      const self = this
      if (this.isLive) {
        const url = '/api/curation/stats'
        const { data } = await axios.get(url).catch((error) => {
          xlibs.requestFailure(error)
        })
        self.userName = data.userName
        self.userEmailAddress = data.userEmailAddress
        self.excludedCount = data.excludedCount
        self.ingestedCount = data.ingestedCount
        self.dateUTC = moment(data.startMs).utc().format('MM/DD/YYYY')
      }
    },
    async getThrottleCurationStats() {
      const self = this
      self.throttleCurationStats = self.throttleCurationStats
        ? self.throttleCurationStats
        : _.throttle(self.getCurationStats, 500)
      self.throttleCurationStats()
    }
  }
}
</script>
