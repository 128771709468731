<template>
  <nav class="subNav navbar-light float-right">
    <div v-if="isEntityCurator" class="mb-3">
      <ul class="nav justify-content-end">
        <li class="nav-item">
          <router-link
            class="btn btn-sm btn-outline-info"
            active-class="active"
            exact
            :to="`${basePath}incoming`"
            tabindex="-1"
          >
            <i class="ion-compose" />Incoming
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            class="btn btn-sm btn-outline-info"
            active-class="active"
            exact
            :to="`${basePath}ingested`"
            tabindex="-1"
          >
            <i class="ion-compose" />Ingested
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            class="btn btn-sm btn-outline-info"
            active-class="active"
            exact
            :to="`${basePath}excluded`"
            tabindex="-1"
          >
            <i class="ion-compose" />Excluded
          </router-link>
        </li>
      </ul>
    </div>
    <div v-if="isLive && isSpotlightAnalyst" class="mb-3">
      <ul class="nav justify-content-end">
        <li class="nav-item">
          <router-link
            class="btn btn-sm btn-outline-primary"
            active-class="active"
            exact
            to="/xman/spotlights/spotlightsPending"
            tabindex="-1"
          >
            <i class="ion-compose" />Pending
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            class="btn btn-sm btn-outline-primary"
            active-class="active"
            exact
            to="/xman/spotlights/spotlightsApproved"
            tabindex="-1"
          >
            <i class="ion-compose" />Approved
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            class="btn btn-sm btn-outline-primary"
            active-class="active"
            exact
            to="/xman/spotlights/spotlightsSuppressed"
            tabindex="-1"
          >
            <i class="ion-compose" />Suppressed
          </router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>
<script>
import xcomputed from '../js/xcomputed'

export default {
  name: 'SubNav',
  computed: {
    ...xcomputed,
    basePath() {
      const path = this.isLive ? '/xman/live/' : ''
      return path
    }
  }
}
</script>
