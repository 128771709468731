import _ from 'lodash'
import xconfig from '../js/xconfig'
const { mutationTypes, pipelines } = xconfig

// for testing
if (navigator.userAgent.indexOf('PhantomJS') > -1) {
  window.localStorage.clear()
}

export const mutations = {
  [mutationTypes.SET_ARTICLES](state, articles) {
    state.articles = [..._.uniqBy(articles, '_id')]
  },
  [mutationTypes.ADD_ARTICLE](state, article) {
    state.articles.push(article)
    state.articles = [..._.uniqBy(state.articles, '_id')]
  },
  [mutationTypes.REMOVE_ARTICLE](state, articleId) {
    const articles = _.filter(state.articles, (article) => {
      return article._id !== articleId
    })
    const difference = state.articles.length - articles.length
    state.articles = [...articles]
    const count = state.count
    count.currentCount = _.size(state.articles)
    count.totalCount = state.count.totalCount - difference
    state.count = { ...count }
  },
  [mutationTypes.UPDATE_ARTICLE](state, article) {
    const articleIndex = _.findIndex(state.articles, ['_id', article._id])
    if (articleIndex > -1) {
      state.articles[articleIndex] = article
      state.articles = [..._.uniqBy(state.articles, '_id')]
    }
  },
  [mutationTypes.SET_INITIAL_ENTITY_CHECKBOX](state, { entityPath }) {
    if (!_.has(state, `${entityPath}.initial_excluded`)) {
      Vue.set(_.get(state, entityPath), 'initial_excluded', _.get(state, `${entityPath}.excluded`))
    }
  },
  [mutationTypes.TOGGLE_ENTITY_CHECKBOX](state, { entityPath }) {
    Vue.set(_.get(state, entityPath), 'excluded', !_.get(state, `${entityPath}.excluded`, true))
    if (!_.get(state, `${entityPath}.dirty`)) {
      Vue.set(_.get(state, entityPath), 'dirty', true)
    }
  },
  [mutationTypes.UPDATE_ARTICLE_APPROVAL](state, { articleId }) {
    const articleIndex = _.findIndex(state.articles, { _id: articleId })
    const allTags = _.flatMap(_.get(state.articles[articleIndex], pipelines.paths.v2.tag, []))
    const excludedRollup = _.reduce(
      allTags,
      function (acc, tag) {
        const excludedChanged = _.has(tag, 'initial_excluded') ? tag.initial_excluded !== tag.excluded : false
        acc.allExcluded = acc.allExcluded && tag.excluded
        acc.dirty = acc.dirty || excludedChanged
        return acc
      },
      { allExcluded: true, dirty: false }
    )

    const approveDisabled =
      state.curatingFrom === 'ingested'
        ? excludedRollup.allExcluded || !excludedRollup.dirty
        : excludedRollup.allExcluded

    Vue.set(state.articles[articleIndex], 'approveDisabled', approveDisabled)
  },
  [mutationTypes.SET_MODAL_ARTICLE](state, modalArticle) {
    state.modalArticle = { ...modalArticle }
  },
  [mutationTypes.SET_EXCLUDE_MODAL_ARTICLE](state, excludeModalArticle) {
    state.excludeModalArticle = { ...excludeModalArticle }
  },
  [mutationTypes.UPDATE_COUNT](state, totalCount) {
    const count = { totalCount }
    count.batchCount = _.get(state, 'count.batchCount', 10)
    count.currentCount = _.size(state.articles) || _.size(state.spotlights)
    state.count = { ...state.count, ...count }
  },
  [mutationTypes.RESET_ARTICLE_FILTERS](state, articleFilters) {
    state.articleFilters = { ...articleFilters }
  },
  [mutationTypes.SET_ARTICLE_FILTERS](state, articleFilters) {
    // reset current page to zero when filters change
    state.articleFilters.page = 0
    _.set(articleFilters, 'sort.field', {})
    state.articleFilters = { ...state.articleFilters, ...articleFilters }
  },
  [mutationTypes.SET_ARTICLE_FILTER](state, articleFilter) {
    // reset current page to zero when filters change
    state.articleFilters.page = 0
    _.set(state, 'articleFilters.sort.field', {})
    state.articleFilters = { ...state.articleFilters, ...articleFilter }
  },
  [mutationTypes.SET_CURATING_FROM](state, curatingFrom) {
    state.curatingFrom = curatingFrom
  },
  [mutationTypes.SET_COLLECTION_TYPE](state, collectionType) {
    state.collectionType = collectionType
  },
  [mutationTypes.SET_COLLECTION_NAME](state, collectionName) {
    state.collectionName = collectionName
  },
  [mutationTypes.TOGGLE_EXPANDED_SCORE](state, scoreKey) {
    const expandedState = _.get(state, ['expandedScores', scoreKey])
    const newExpandedState = { [scoreKey]: !expandedState }
    state.expandedScores = { ...state.expandedScores, ...newExpandedState }
  },
  [mutationTypes.LOCAL_SORT_ARICLES](state, fieldSort = {}) {
    const articles = _.cloneDeep(state.articles)
    state.articleFilters.sort = { ...state.articleFilters.sort, ...{ field: fieldSort } }
    if (!_.isEmpty(fieldSort)) {
      const sortKey = fieldSort.type === 'headline' ? 'title' : fieldSort.type
      const sortedArticles = _.orderBy(articles, sortKey, fieldSort.direction)
      state.articles = sortedArticles
    }
  },
  [mutationTypes.LOCAL_SORT_SPOTLIGHTS](state, fieldSort = {}) {
    const spotlights = _.cloneDeep(state.spotlights)
    state.articleFilters.sort = { ...state.articleFilters.sort, ...{ field: fieldSort } }
    if (!_.isEmpty(fieldSort)) {
      const sortKey = fieldSort.type === 'headline' ? 'title' : 'source.name'
      const sortedSpotlights = _.orderBy(spotlights, `article.${sortKey}`, fieldSort.direction)
      state.spotlights = sortedSpotlights
    }
  },
  [mutationTypes.CLEAN_ARTICLE_TAGS](state, articleId) {
    const articleIndex = _.findIndex(state.articles, { _id: articleId })
    const article = state.articles[articleIndex]
    _.each(_.get(article, pipelines.paths.v2.tag), (tag) => {
      delete tag.initial_excluded
      delete tag.dirty
    })
    Vue.set(state.articles, articleIndex, article)
  },
  [mutationTypes.ADD_POLARITY_CHANGE](state, { polarityChangePath, change }) {
    const polarityChanges = { ...state.polarityChanges }
    _.set(polarityChanges, polarityChangePath, change)
    state.polarityChanges = polarityChanges
  },
  [mutationTypes.CLEAR_POLARITY_CHANGE](state, { polarityChangePath }) {
    const polarityChanges = _.cloneDeep(state.polarityChanges) || {}
    _.unset(polarityChanges, polarityChangePath)
    const pathArray = _.split(polarityChangePath, '.')
    while (--pathArray.length && _.isEmpty(_.get(polarityChanges, pathArray))) {
      _.unset(polarityChanges, pathArray)
    }
    state.polarityChanges = polarityChanges
  },
  [mutationTypes.CLEAR_All_POLARITY_CHANGES](state) {
    state.polarityChanges = {}
  },
  [mutationTypes.ADD_SPOTLIGHT_CHANGE](state, { spotlightChangePath, change }) {
    const spotlightChanges = { ...state.spotlightChanges }
    _.set(spotlightChanges, spotlightChangePath, change)
    state.spotlightChanges = spotlightChanges
  },
  [mutationTypes.CLEAR_SPOTLIGHT_CHANGE](state, { spotlightChangePath }) {
    const spotlightChanges = { ...state.spotlightChanges }
    _.unset(spotlightChanges, spotlightChangePath)
    state.spotlightChanges = spotlightChanges
  },
  [mutationTypes.CLEAR_All_SPOTLIGHT_CHANGES](state) {
    state.spotlightChanges = {}
  },
  [mutationTypes.SET_SPOTLIGHT_CURATION_ACTION](state, { spotlightId, curationAction }) {
    _.set(state.spotlightCurationAction, spotlightId, curationAction)
  },
  [mutationTypes.CLEAR_SPOTLIGHT_CURATION_ACTIONS](state) {
    state.spotlightCurationAction = {}
  },
  [mutationTypes.SET_SPOTLIGHTS](state, spotlights) {
    state.spotlights = [...spotlights]
  },
  [mutationTypes.ADD_SPOTLIGHT](state, spotlight) {
    state.spotlights.push(spotlight)
    state.spotlights = [..._.uniqBy(state.spotlights, '_id')]
  },
  [mutationTypes.REMOVE_SPOTLIGHT](state, spotlightId) {
    const spotlights = _.filter(state.spotlights, (spotlight) => {
      return spotlight.spotlightId !== spotlightId
    })
    const difference = state.spotlights.length - spotlights.length
    state.spotlights = [...spotlights]
    const count = state.count
    count.currentCount = _.size(state.spotlights)
    count.totalCount = state.count.totalCount - difference
    state.count = { ...count }
    delete state.spotlightCurationAction[spotlightId]

    const spotlightCurationAction = { ...state.spotlightCurationAction }
    _.unset(spotlightCurationAction, spotlightId)
    state.spotlightCurationAction = spotlightCurationAction
  }
}
