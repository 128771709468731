import xlibs from '../js/xlibs'
import xconfig from '../js/xconfig'
const { mutationTypes, articleFilterDefaults } = xconfig

export default {
  setArticles({ commit }, articles, updateCheckStates = true) {
    if (updateCheckStates) {
      articles = xlibs.setApproveDisabled(articles)
      articles = xlibs.setCurationDisabled(articles)
      articles = xlibs.setEntityTagCheckboxes(articles)
    }
    commit(mutationTypes.SET_ARTICLES, articles)
  },
  addArticles({ commit }, articles) {
    articles = xlibs.setApproveDisabled(articles)
    articles = xlibs.setCurationDisabled(articles)
    articles = xlibs.setEntityTagCheckboxes(articles)
    _.each(articles, (article) => commit(mutationTypes.ADD_ARTICLE, article))
  },
  clearArticles({ commit }) {
    commit(mutationTypes.SET_ARTICLES, [])
  },
  removeArticle({ commit }, articleId) {
    commit(mutationTypes.REMOVE_ARTICLE, articleId)
  },
  updateArticle({ commit }, article) {
    commit(mutationTypes.UPDATE_ARTICLE, article)
  },
  toggleCheckbox({ commit, state }, { articleId, entityPath }) {
    if (state.curatingFrom === 'ingested') {
      commit(mutationTypes.SET_INITIAL_ENTITY_CHECKBOX, { entityPath })
    }
    commit(mutationTypes.TOGGLE_ENTITY_CHECKBOX, { entityPath })
    commit(mutationTypes.UPDATE_ARTICLE_APPROVAL, { articleId })
  },
  setModalArticle({ commit }, modalArticle) {
    commit(mutationTypes.SET_MODAL_ARTICLE, modalArticle)
  },
  setExcludeModalArticle({ commit }, excludeModalArticle) {
    commit(mutationTypes.SET_EXCLUDE_MODAL_ARTICLE, excludeModalArticle)
  },
  updateCount({ commit }, totalCount) {
    commit(mutationTypes.UPDATE_COUNT, totalCount)
  },
  setArticleFilters({ commit }, articleFilters) {
    commit(mutationTypes.SET_ARTICLE_FILTERS, articleFilters)
  },
  setArticleFilter({ commit }, articleFilter) {
    commit(mutationTypes.SET_ARTICLE_FILTER, articleFilter)
  },
  resetArticleFilters({ commit }, view) {
    const articleFilters = articleFilterDefaults[view]
    commit(mutationTypes.RESET_ARTICLE_FILTERS, articleFilters)
  },
  setCuratingFrom({ commit }, curatingFrom) {
    commit(mutationTypes.SET_CURATING_FROM, curatingFrom)
  },
  setCollectionType({ commit }, collectionType) {
    commit(mutationTypes.SET_COLLECTION_TYPE, collectionType)
  },
  setCollectionName({ commit }, collectionName) {
    commit(mutationTypes.SET_COLLECTION_NAME, collectionName)
  },
  toggleExpandedScore({ commit }, scoreKey) {
    commit(mutationTypes.TOGGLE_EXPANDED_SCORE, scoreKey)
  },
  localSortArticles({ commit }, fieldSort) {
    commit(mutationTypes.LOCAL_SORT_ARICLES, fieldSort)
  },
  localSortSpotlights({ commit }, fieldSort) {
    commit(mutationTypes.LOCAL_SORT_SPOTLIGHTS, fieldSort)
  },
  clearLocalSortArticles({ commit }) {
    commit(mutationTypes.LOCAL_SORT_ARICLES)
  },
  cleanArticleStateTags({ commit }, articleId) {
    commit(mutationTypes.CLEAN_ARTICLE_TAGS, articleId)
  },
  addPolarityChange({ commit }, { polarityChangePath, change }) {
    commit(mutationTypes.ADD_POLARITY_CHANGE, { polarityChangePath, change })
  },
  clearPolarityChange({ commit }, { polarityChangePath }) {
    commit(mutationTypes.CLEAR_POLARITY_CHANGE, { polarityChangePath })
  },
  clearAllPolarityChanges({ commit }) {
    commit(mutationTypes.CLEAR_All_POLARITY_CHANGES)
  },
  addSpotlightChange({ commit }, { spotlightChangePath, change }) {
    commit(mutationTypes.ADD_SPOTLIGHT_CHANGE, { spotlightChangePath, change })
  },
  clearSpotlightChange({ commit }, { spotlightChangePath }) {
    commit(mutationTypes.CLEAR_SPOTLIGHT_CHANGE, { spotlightChangePath })
  },
  clearAllSpotlightChanges({ commit }) {
    commit(mutationTypes.CLEAR_All_SPOTLIGHT_CHANGES)
  },
  setSpotlightCurationAction({ commit }, { spotlightId, curationAction }) {
    commit(mutationTypes.SET_SPOTLIGHT_CURATION_ACTION, { spotlightId, curationAction })
  },
  clearSpotlightCurationActions({ commit }) {
    commit(mutationTypes.CLEAR_SPOTLIGHT_CURATION_ACTIONS)
  },
  setSpotlights({ commit }, spotlights, updateCheckStates = true) {
    if (updateCheckStates) {
      spotlights = xlibs.setCurationDisabled(spotlights)
    }
    commit(mutationTypes.SET_SPOTLIGHTS, spotlights)
  },
  addSpotlights({ commit }, spotlights) {
    spotlights = xlibs.setCurationDisabled(spotlights)
    _.each(spotlights, (spotlight) => commit(mutationTypes.ADD_SPOTLIGHT, spotlight))
  },
  removeSpotlight({ commit }, spotlightId) {
    commit(mutationTypes.REMOVE_SPOTLIGHT, spotlightId)
  }
}
