import Vue from 'vue'
import Vuex from 'vuex'
import { mutations } from './mutations'
import actions from './actions'
import getters from './getters'
// import plugins from './plugins'
// import xconfig from '../js/xconfig'
// const { STORAGE_KEY } = xconfig

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    articles: [],
    modalArticle: {},
    curatingFrom: '',
    excludeModalArticle: {},
    count: { totalCount: 0, currentCount: 0, batchCount: 10 },
    articleFilters: {},
    expandedScores: { sasb: false, sdg: false },
    collectionType: '',
    collectionName: '',
    polarityChanges: {},
    spotlightChanges: {},
    spotlightCurationAction: {},
    spotlights: []
    // articles: JSON.parse(window.localStorage.getItem(STORAGE_KEY) || "[]")
  },
  actions,
  mutations,
  getters
  // plugins
})
