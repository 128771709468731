<template>
  <div class="articleDateInfo">
    <div v-if="_.get(article, 'articlePubDateMs')">
      {{ formattedDate(article.articlePubDateMs) }}
      <div class="local-time">local: {{ formattedDate(article.articlePubDateMs, true) }}</div>
    </div>
    <div v-if="spotlightId" class="spotlightIdCopy">
      <div class="label">Spotlight Id:</div>
      <buttonCopy :value="spotlightId" />
    </div>
    <div v-if="showDocumentHash && _.get(article, 'documentHash')">
      <documentHashCopy :document-hash="article.documentHash" />
    </div>
  </div>
</template>

<script>
import xcomputed from '../js/xcomputed'
import xlibs from '../js/xlibs'
import documentHashCopy from '../components/documentHashCopy'
import buttonCopy from '../components/buttons/buttonCopy'

export default {
  name: 'ArticleDateInfo',
  components: {
    buttonCopy,
    documentHashCopy
  },
  props: {
    article: {
      type: Object,
      default: null
    },
    showDocumentHash: {
      type: Boolean,
      default: true
    },
    spotlightId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      currentUser: {}
    }
  },
  computed: {
    ...xcomputed
  },
  mounted() {
    this.currentUser = xlibs.getUser()
  },
  methods: {
    ...xlibs
  }
}
</script>
