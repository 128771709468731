<template>
  <nav class="mainNav navbar navbar-light">
    <div v-if="isSiteHeader" id="alert-container" class="col offset-sm-2 col-sm-8" />
    <div v-if="isSiteHeader" class="container">
      <router-link class="navbar-brand slanted" exact tabindex="-1" to="/xman" :class="{ static: isStatic }">
        XMAN <span v-if="isStatic" class="xman-mode">static</span>
      </router-link>
    </div>
    <div v-if="isSiteHeader" class="logout-nav">
      <ul class="nav nav-bar">
        <li class="nav-item">
          <a href="#" class="btn btn-sm btn-outline-primary" tabindex="-1" @click="logout()"> Logout </a>
        </li>
      </ul>
    </div>
    <div class="container">
      <ul class="nav">
        <li v-if="isLive & isEntityCurator" class="nav-item">
          <router-link
            class="nav-link btn"
            :class="{
              'btn-sm btn-outline-secondary': isSiteHeader,
              'btn-outline-primary': !isSiteHeader
            }"
            active-class="active"
            to="/xman/live"
            tabindex="-1"
          >
            <i class="ion-compose" />Live Articles
          </router-link>
        </li>
        <li v-if="isLive & isSpotlightAnalyst" class="nav-item">
          <router-link
            class="nav-link btn"
            :class="{
              'btn-sm btn-outline-secondary': isSiteHeader,
              'btn-outline-primary': !isSiteHeader
            }"
            active-class="active"
            to="/xman/spotlights"
            tabindex="-1"
          >
            <i class="ion-compose" />Spotlight Review
          </router-link>
        </li>
        <li v-if="isStatic" class="nav-item">
          <router-link
            class="nav-link btn"
            :class="{
              'btn-sm btn-outline-secondary': isSiteHeader,
              'btn-outline-primary': !isSiteHeader
            }"
            active-class="active"
            exact
            to="/xman/static"
            tabindex="-1"
          >
            <i class="ion-compose" />Static Articles
          </router-link>
        </li>
        <li v-if="isAdmin && isLive" class="nav-item">
          <router-link
            class="nav-link btn"
            :class="{
              'btn-sm btn-outline-secondary': isSiteHeader,
              'btn-outline-primary': !isSiteHeader
            }"
            active-class="active"
            exact
            to="/xman/reports"
            tabindex="-1"
          >
            <i class="ion-compose" />Reports
          </router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>
<script>
import xlibs from '../js/xlibs'
import xcomputed from '../js/xcomputed'

export default {
  name: 'NavHeader',
  props: {
    isSiteHeader: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...xcomputed
  },
  methods: {
    logout: xlibs.logout
  }
}
</script>
