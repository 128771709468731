<template>
  <b-button variant="outline-info" size="sm" tabindex="-1" class="btn-xs buttonCopy" @click="copy(value, title)">
    {{ value }}
  </b-button>
</template>

<script>
import xlibs from '../../js/xlibs'
const { copy } = xlibs

export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: 'Copied text'
    }
  },

  methods: {
    copy
  }
}
</script>
