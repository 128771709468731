import xlibs from './xlibs'
import xconfig from './xconfig'

const { pipelines } = xconfig
const xentities = {
  checkEntity(entityIndex, entityTvlId, pipeline) {
    const tagPath = pipelines.paths[pipeline].tag
    const articleId = this.article._id
    let siblingPipeline = pipeline === pipelines.v2 ? pipelines.v3 : pipelines.v2
    const entityPath = `articles[${this.articleIndex}].${tagPath}[${entityIndex}]`
    const entityExcludedValue = _.get(this.article, `${tagPath}[${entityIndex}].excluded`)
    let siblingEnitiyIndex
    if (siblingPipeline && !_.get(this.article, `${tagPath}[${entityIndex}].dirty`)) {
      const siblingTagPath = _.get(pipelines, `paths[${siblingPipeline}].tag`)
      if (siblingTagPath) {
        siblingEnitiyIndex = _.findIndex(_.get(this.article, siblingTagPath), {
          tvlId: entityTvlId,
          excluded: entityExcludedValue
        })
      }
    }
    this.$store.dispatch('toggleCheckbox', {
      articleId,
      entityPath
    })

    if (siblingEnitiyIndex >= 0) {
      this.checkEntity(siblingEnitiyIndex, entityTvlId, siblingPipeline)
      const checkedState = entityExcludedValue ? 'checked' : 'unchecked'
      this.$bvToast.toast(`Also ${checkedState} matching ${siblingPipeline} entity`, {
        variant: 'warning',
        title: `${_.capitalize(checkedState)} Paired Entity`,
        autoHideDelay: 1000
      })
      // xlibs.showMessageAlert(`Also unchecked matching <b>${siblingPipeline}</b> entity`, 'warning')
    }
  },
  toggleLabel(event, showText = 'Show', hideText = 'Hide') {
    let spanned = $(event.target).find('span')
    if (spanned.text() !== hideText) {
      spanned.text(hideText)
    } else {
      spanned.text(showText)
    }
  },
  hasAliases(internalAliases) {
    const aliases = xlibs.getAliases(internalAliases)
    return aliases.length
  },
  removeNull(obj) {
    return _.reject(obj, (value) => value === null)
  },
  getAliases(internalAliases, lowerCase) {
    if (!_.isString(internalAliases) && !_.isArray(internalAliases)) {
      return []
    }
    const aliasArray = []
    const initialAliasArray = _.isString(internalAliases) ? internalAliases.split(';') : internalAliases
    _.each(initialAliasArray, function (alias) {
      let trimmedAlias = _.trim(alias)
      if (lowerCase) {
        trimmedAlias = _.lowerCase(trimmedAlias)
      }
      if (trimmedAlias) {
        aliasArray.push(trimmedAlias)
      }
    })
    return aliasArray
  },
  getDynamicTag(tagPrefix, id, articleIndex, pipeline) {
    return `${tagPrefix}_${id}_${articleIndex}_${pipeline}`
  },
  getBadgeClass() {
    const badge = 'badge-'
    let type
    switch (this.currentPipeline) {
      case pipelines.v2:
        type = 'success'
        break
      case pipelines.v3:
        type = 'warning'
        break
    }
    return `${badge}${type}`
  },
  getEntityId(entity) {
    const idPath = _.get(pipelines, ['paths', this.currentPipeline, 'entityId'])
    return _.get(entity, idPath)
  },
  getEntityName(entity) {
    const namePath = _.get(pipelines, ['paths', this.currentPipeline, 'name'])
    return _.get(entity, namePath)
  },
  getEntityTypeLabel(pipeline) {
    const entityType = _.get(pipelines, ['paths', pipeline, 'entityId'])
    return `${entityType}`
  },
  getScores(entity, article, type) {
    const path = _.get(pipelines, ['paths', this.currentPipeline, 'scores', type])
    return _.get(entity, path)
  },
  getScorePolarityLevel(score) {
    let level = 0
    if (_.isNaN(_.toNumber(score))) return 0
    _.each(xconfig.polarityScoreLevels, (levelObj) => {
      if (levelObj.level && _.inRange(score, levelObj.low, levelObj.high)) {
        level = levelObj.level
        return false
      }
    })
    return level
  },
  getScorePolarityClass(value, type = 'score') {
    const level = type === 'score' ? this.getScorePolarityLevel(value) : value
    const prefix = 'score-'
    let textLevel
    switch (true) {
      case level === 5:
        textLevel = 'veryhigh'
        break
      case level === 4:
        textLevel = 'high'
        break
      case level === 3:
        textLevel = 'medium'
        break
      case level === 2:
        textLevel = 'low'
        break
      case level === 1:
        textLevel = 'verylow'
        break
      default:
        textLevel = 'na'
    }
    return `${prefix}${textLevel}`
  }
}

export default xentities
