<template>
  <a
    ref="approveBtn"
    v-inview:class="{ onpage: true }"
    v-inview:class.leave="{ onpage: false }"
    class="approve"
    :class="{
      disabled:
        curationChangeWindowExpired ||
        article.approveDisabled ||
        !_.isEmpty(article.curationDisabled) ||
        featureSwitches.disableArticleCuration,
      curationExpired: curationChangeWindowExpired
    }"
    :tabindex="article.approveDisabled || !_.isEmpty(article.curationDisabled) ? -1 : 0"
    title="Approve Selected"
    href="#"
    @click.prevent="approveArticle(article._id)"
  >
    <span v-if="curationChangeWindowExpired" class="curationChangeWindowExpired">
      <i class="fa fa-icicles" />
      <i class="fa fa-check-circle" />
    </span>
    <span v-if="!curationChangeWindowExpired">
      <span v-if="subjectToChangeWindow" class="subjectToChangeWindow">
        <i class="fa fa-clock expire-warning" />
        <i class="fa fa-check-circle" />
      </span>
      <span v-if="!subjectToChangeWindow">
        <i class="fa fa-check-circle" />
      </span>
    </span>
  </a>
</template>

<script>
import { mapGetters } from 'vuex'
import xcomputed from '../../js/xcomputed'
import xlibs from '../../js/xlibs'
const { approveArticle, prepareApprovalModel } = xlibs

export default {
  props: {
    article: {
      type: Object,
      default: null
    },
    articleIndex: {
      type: Number,
      default: null
    }
  },
  computed: {
    isAdmin: xcomputed.isAdmin,
    isLive: xcomputed.isLive,
    isStatic: xcomputed.isStatic,
    curationItemType: xcomputed.curationItemType,
    featureSwitches: xcomputed.featureSwitches,
    subjectToChangeWindow: xcomputed.subjectToChangeWindow,
    curationChangeWindowExpired: xcomputed.curationChangeWindowExpired,
    ...mapGetters(['articles', 'articleFilters', 'curatingFrom', 'collectionType', 'collectionName'])
  },
  methods: {
    approveArticle,
    prepareApprovalModel
  }
}
</script>
