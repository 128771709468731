// flagcodes: https://www.npmjs.com/package/vue-country-flag
const xconfig = {
  arlertTimeoutDuration: 2500,
  languages: [
    { code: 'en', name: 'English', default: true, flagCode: 'us' },
    { code: 'non-en', name: 'Non-English' },
    { code: 'zh-Hans', name: 'Chinese (simplified)', flagCode: 'cn' },
    { code: 'zh-Hant', name: 'Chinese (traditional)', flagCode: 'cn' },
    { code: 'fr', name: 'French', flagCode: 'fr' },
    { code: 'de', name: 'German', flagCode: 'de' },
    { code: 'it', name: 'Italian', flagCode: 'it' },
    { code: 'ja', name: 'Japanese', flagCode: 'jp' },
    { code: 'pt', name: 'Portuguese', flagCode: 'pt' },
    { code: 'es', name: 'Spanish', flagCode: 'es' },
    { code: 'da', name: 'Danish', flagCode: 'dk' },
    { code: 'nl', name: 'Dutch', flagCode: 'nl' },
    { code: 'nb', name: 'Norwegian', flagCode: 'no' },
    { code: 'sv', name: 'Swedish', flagCode: 'se' },
    { code: 'fi', name: 'Finnish', flagCode: 'fi' },
    { code: 'el', name: 'Bulgarian', flagCode: 'bg' },
    { code: 'he', name: 'Greek', flagCode: 'gr' },
    { code: 'hr', name: 'Hebrew', flagCode: 'il' },
    { code: 'hu', name: 'Croatian', flagCode: 'hr' },
    { code: 'is', name: 'Icelandic', flagCode: 'is' },
    { code: 'lt', name: 'Lithuanian', flagCode: 'lt' },
    { code: 'mk', name: 'Macedonian', flagCode: 'mk' },
    { code: 'pl', name: 'Polish', flagCode: 'pl' },
    { code: 'ro', name: 'Romanian', flagCode: 'ro' },
    { code: 'sk', name: 'Slovakian', flagCode: 'sk' },
    { code: 'sl', name: 'Slovenian', flagCode: 'si' },
    { code: 'sr', name: 'Serbian', flagCode: 'rs' },
    { code: 'th', name: 'Thai', flagCode: 'th' },
    { code: 'uk', name: 'Ukranian', flagCode: 'ua' },
    { code: 'ru', name: 'Russian', flagCode: 'ru' },
    { code: 'vi', name: 'Vietnamese', flagCode: 'vn' },
    { code: 'cs', name: 'Czech', flagCode: 'cz' },
    { code: 'all', name: 'All' }
  ],
  languageButtonLabel: {
    off: 'Show Original',
    on: 'Translate to English'
  },
  disableReportDurations: {
    curatorsReport: 21,
    versionsReport: 28,
    reportItemsDownload: {
      articles: 3,
      spotlights: 7
    }
  },
  defaultStartDate: '01/01/2016',
  defaultStartDateStatic: '01/01/2007',
  dateFormat: 'MM/DD/YYYY',
  defaultUserSelection: { _id: '', email: '-- assign user --' },
  exclusionOptions: [
    {
      text: 'Company and Context',
      selections: [
        { text: "Company isn't mentioned in the article at all" },
        { text: 'Company profile' },
        { text: 'Person mentioned' },
        { text: 'Irrelevant reference - tech and media' },
        { text: 'Irrelevant reference - finance' },
        { text: 'Market report' },
        { text: 'Egregious Press Release' },
        { text: 'Company research' },
        { text: 'Company is mentioned but there isn’t any action language' }
      ]
    },
    {
      text: 'Article-Level',
      selections: [{ text: 'Formatting/display issue' }]
    },
    {
      text: 'ESG Relevant',
      selections: [{ text: 'Article does not contain ESG relevant content' }]
    }
  ],
  keyboardMap: {
    enter: 13,
    escape: 27,
    spacebar: 32,
    left: 37,
    right: 39,
    up: 38,
    down: 40
  },
  mutationTypes: {
    SET_ARTICLES: 'SET_ARTICLES',
    ADD_ARTICLE: 'ADD_ARTICLE',
    SET_ARTICLE_FILTERS: 'SET_ARTICLE_FILTERS',
    RESET_ARTICLE_FILTERS: 'RESET_ARTICLE_FILTERS',
    SET_ARTICLE_FILTER: 'SET_ARTICLE_FILTER',
    UPDATE_ARTICLE_APPROVAL: 'UPDATE_ARTICLE_APPROVAL',
    SET_MODAL_ARTICLE: 'SET_MODAL_ARTICLE',
    SET_EXCLUDE_MODAL_ARTICLE: 'SET_EXCLUDE_MODAL_ARTICLE',
    REMOVE_ARTICLE: 'REMOVE_ARTICLE',
    SET_INITIAL_ENTITY_CHECKBOX: 'SET_INITIAL_ENTITY_CHECKBOX',
    TOGGLE_ENTITY_CHECKBOX: 'TOGGLE_ENTITY_CHECKBOX',
    UPDATE_ARTICLE: 'UPDATE_ARTICLE',
    UPDATE_COUNT: 'UPDATE_COUNT',
    SET_CURATING_FROM: 'SET_CURATING_FROM',
    SET_COLLECTION_TYPE: 'SET_COLLECTION_TYPE',
    SET_COLLECTION_NAME: 'SET_COLLECTION_NAME',
    TOGGLE_EXPANDED_SCORE: 'TOGGLE_EXPANDED_SCORE',
    LOCAL_SORT_ARICLES: 'LOCAL_SORT_ARICLES',
    LOCAL_SORT_SPOTLIGHTS: 'LOCAL_SORT_SPOTLIGHTS',
    CLEAN_ARTICLE_TAGS: 'CLEAN_ARTICLE_TAGS',
    ADD_POLARITY_CHANGE: 'ADD_POLARITY_CHANGE',
    CLEAR_POLARITY_CHANGE: 'CLEAR_POLARITY_CHANGE',
    CLEAR_All_POLARITY_CHANGES: 'CLEAR_All_POLARITY_CHANGES',
    ADD_SPOTLIGHT_CHANGE: 'ADD_SPOTLIGHT_CHANGE',
    CLEAR_SPOTLIGHT_CHANGE: 'CLEAR_SPOTLIGHT_CHANGE',
    CLEAR_All_SPOTLIGHT_CHANGES: 'CLEAR_All_SPOTLIGHT_CHANGES',
    SET_SPOTLIGHT_CURATION_ACTION: 'SET_SPOTLIGHT_CURATION_ACTION',
    CLEAR_SPOTLIGHT_CURATION_ACTIONS: 'CLEAR_SPOTLIGHT_CURATION_ACTIONS',
    SET_SPOTLIGHTS: 'SET_SPOTLIGHTS',
    ADD_SPOTLIGHT: 'ADD_SPOTLIGHT',
    REMOVE_SPOTLIGHT: 'REMOVE_SPOTLIGHT'
  },
  STORAGE_KEY: 'x-articles',
  articleFilterDefaults: {
    incoming: {
      sort: {
        date: {
          direction: 'asc',
          dateRangeType: 'articlePubDate'
        },
        field: {
          type: '',
          direction: ''
        }
      },
      lens: 'all',
      language: 'en',
      pipeline: 'v2',
      companyReportingType: 'normal',
      page: 0,
      limit: 10
    },
    ingested: {
      sort: {
        date: {
          direction: 'desc',
          dateRangeType: 'articlePubDate'
        },
        field: {
          type: '',
          direction: ''
        }
      },
      user: 'none',
      language: 'all',
      polarityScoreModified: 'any',
      lens: 'all',
      page: 0,
      limit: 50
    },
    excluded: {
      sort: {
        date: {
          direction: 'desc',
          dateRangeType: 'articlePubDate'
        },
        field: {
          type: '',
          direction: ''
        }
      },
      user: 'none',
      language: 'all',
      lens: 'all',
      page: 0,
      limit: 50
    },
    spotlightsPending: {
      sort: {
        date: {
          direction: 'asc',
          dateRangeType: 'hourMs'
        },
        field: {
          type: '',
          direction: ''
        }
      },
      lens: 'all',
      language: 'all',
      pipeline: 'v3',
      page: 0,
      limit: 10
    },
    spotlightsSuppressed: {
      sort: {
        date: {
          direction: 'desc',
          dateRangeType: 'curationDate'
        },
        field: {
          type: '',
          direction: ''
        }
      },
      lens: 'all',
      language: 'all',
      pipeline: 'v3',
      page: 0,
      limit: 10,
      spotlightChanges: {}
    },
    spotlightsApproved: {
      sort: {
        date: {
          direction: 'desc',
          dateRangeType: 'curationDate'
        },
        field: {
          type: '',
          direction: ''
        }
      },
      lens: 'all',
      language: 'all',
      pipeline: 'v3',
      page: 0,
      limit: 10,
      spotlightChanges: {}
    },
    reports: {
      sort: {
        date: {
          direction: 'desc',
          dateRangeType: 'curationDate'
        },
        field: {
          type: '',
          direction: ''
        }
      },
      curation: 'curated',
      lens: 'all',
      language: 'all',
      polarityScoreModified: 'any',
      pipeline: 'v2',
      reportDetailType: 'detail',
      reportEntityType: 'articles',
      user: 'none',
      limit: 50
    }
  },
  pipelines: {
    v2: 'v2',
    v3: 'v3',
    paths: {
      v2: {
        tag: 'dpv2.associationTags',
        entityId: 'tvlId',
        name: 'longName',
        scores: { sasb: 'categories.SASBscores', sdg: 'categories.SDGscores' }
      },
      v3: {
        tag: 'dpv3.associationTags',
        entityId: 'tvlId',
        name: 'longName',
        scores: { sasb: 'categories.SASBscores', sdg: 'categories.SDGscores' }
      }
    }
  },
  lenses: [{ key: 'sasb', name: 'SASB', spotlightsEnabled: true }],
  dateRangeTypes: {
    articlePubDate: { key: 'articlePubDate', title: 'Pub Date', articles: true, articlesOn: true },
    curationDate: {
      key: 'curationDate',
      title: 'Action Date',
      articles: true,
      spotlights: true,
      spotlightsOn: false,
      articlesOn: false
    },
    hourMs: { key: 'hourMs', title: 'Trigger Date', spotlights: true, spotlightsOn: true }
  },
  GRID_COLUMNS: 12,
  curationTypes: {
    ingested: 'ingested',
    excluded: 'excluded',
    approved: 'approved',
    suppressed: 'suppressed'
  },
  reportDetailTypes: {
    detail: 'detail',
    summary: 'summary',
    change: 'change'
  },
  PRESS_RELEASE: 'press release',
  pressReleaseTerms: [
    'pr newswire',
    'investor relations',
    'press release',
    'source:',
    'original press release:',
    'key kighlights:',
    'distributed by companies',
    'corporate press release details',
    'full communiqué here:',
    'view original content.'
  ]
}

xconfig.exclusionOptions.forEach((option) => {
  option.key = _.camelCase(option.text)
  option.selections.forEach((selection) => {
    selection.key = _.camelCase(selection.text)
  })
})

xconfig.polarityScoreLevels = [
  { level: 0 },
  { level: 1, low: 0.0001, high: 20 },
  { level: 2, low: 20, high: 40 },
  { level: 3, low: 40, high: 60 },
  { level: 4, low: 60, high: 80 },
  { level: 5, low: 80, high: 100.1 }
]

xconfig.polarityLevel2Score = {
  0: 0,
  1: 10,
  2: 30,
  3: 50,
  4: 70,
  5: 90
}

xconfig.formsConfig = {
  headline: {
    maxLength: 40,
    minLength: 3
  },
  companyId: {
    maxLength: 36,
    minLength: 12
  },
  spotlightId: {
    maxLength: 36,
    minLength: 36
  },
  documentHash: {
    length: 40
  }
}
xconfig.authCookieName = 'xAuthToken'
const ExpirationTesting = false // for easy testing
const ExpirationUnit = ExpirationTesting ? 'minute' : 'hour'
xconfig.recurationWindow = { duration: moment.duration(1, ExpirationUnit), unit: ExpirationUnit }
xconfig.curationExpirationWarningLevels = ExpirationTesting
  ? { unit: 'seconds', mild: 40, strong: 20 }
  : { unit: 'minutes', mild: 5, strong: 1 }
if (ExpirationTesting) {
  console.log('EXPIRATION TESTING: curation expiration set to 1 minute for Testing')
  console.log('curationExpirationWarningLevels', xconfig.curationExpirationWarningLevels)
}
export default xconfig
