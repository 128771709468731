<template>
  <div class="reports-page main-template list-manager">
    <div class="container-fluid title">
      <div class="form-row">
        <div class="col col-md-4 d-flex h4">List Manager</div>
      </div>
      <div class="form-row">
        <div class="col col-md-4 d-flex">
          <select
            id="list-type-selector"
            v-model="listType"
            name="clist-type-selector"
            class="form-control-sm"
            :disabled="mode === 'edit'"
            @change="getCompanyList()"
          >
            <option value="reportPublishing">Report-Publishing Cos</option>
            <option value="finance">Finance Cos</option>
            <option value="techMedia">Tech & Media Cos</option>
          </select>
        </div>
      </div>
    </div>
    <div class="container-fluid buttons">
      <div class="form-row date-row pt-3">
        <div class="col col-md-4 d-flex">
          <h6 v-if="isStatic">* Edit in live xman</h6>
          <button
            v-if="mode === 'view' && !isStatic"
            type="button"
            class="btn btn-sm btn-info mt-auto"
            @click="editMode()"
          >
            edit list
          </button>

          <button v-if="mode === 'edit'" type="button" class="btn btn-sm btn-secondary mt-auto" @click="viewMode()">
            cancel
          </button>

          <button v-if="mode === 'edit'" type="button" class="btn btn-sm btn-info mt-auto" @click="updateCompanyList()">
            submit
          </button>
        </div>
      </div>
    </div>
    <div class="container-fluid list-view">
      <div v-if="mode === 'view'" class="rp-header">
        {{ managedCompanyList.length }} {{ _.startCase(listType) }}
        {{ managedCompanyList.length | pluralize(['Company', 'Companies']) }}
        (orgIds)
      </div>
      <div v-if="mode === 'edit'">
        Please enter one orgTvlId per line
        <i>(e.g. f2307fc0-4ad7-4680-88b8-33ae03a2dcdb)</i><br />No additional punctuation.
      </div>
      <div v-if="mode === 'view'" class="company-list">
        <table>
          <tr v-for="company in managedCompanyList" :key="company.tvlId">
            <td>{{ company.tvlId }}</td>
            <td>-</td>
            <td class="longName">{{ company.longName }}</td>
          </tr>
        </table>
      </div>
      <div v-if="mode === 'edit'">
        <textarea id="editedList" v-model="companiesEditList" rows="30" cols="50" />
      </div>
    </div>
    <div id="search-spinner" class="container-fluid">
      <div class="col screen-centered"><div class="spinner" /></div>
    </div>
  </div>
</template>

<script>
import xlibs from '../js/xlibs'
import xcomputed from '../js/xcomputed'

export default {
  name: 'ListManager',
  data() {
    return {
      managedCompanyList: [],
      listType: 'reportPublishing',
      mode: 'view',
      separator: '\n',
      companiesEditList: ''
    }
  },
  computed: {
    ...xcomputed
  },
  mounted() {
    this.getCompanyList()
  },
  methods: {
    async updateCompanyList() {
      const self = this
      const companyArray = _.compact(self.companiesEditList.split(self.separator))
      const companies = _.map(companyArray, (companyId) => {
        return { tvlId: _.trim(companyId) }
      })
      const url = `/api/companyLists/${self.listType}`
      const params = { companies }
      xlibs.showSearchSpinner()
      const { data } = await axios
        .post(url, params)
        .catch((error) => {
          xlibs.requestFailure(error)
        })
        .finally(() => {
          xlibs.showSearchSpinner(false)
        })
      if (data) {
        const { invalidCompanies } = data
        if (invalidCompanies.length) {
          xlibs.showErrorAlert(`rejected invalid ids: ${invalidCompanies}`)
        }
        self.getCompanyList()
        self.mode = 'view'
      }
    },
    editMode() {
      const self = this
      self.mode = 'edit'
      self.companiesEditList = _.join(_.flatMap(self.managedCompanyList, 'tvlId'), self.separator)
    },
    viewMode() {
      const self = this
      self.mode = 'view'
    },
    async getCompanyList() {
      const self = this
      const url = `/api/companyLists/${self.listType}`
      xlibs.showSearchSpinner()
      const { data } = await axios
        .get(url)
        .catch((error) => {
          xlibs.requestFailure(error)
        })
        .finally(() => {
          xlibs.showSearchSpinner(false)
        })
      self.managedCompanyList = data || []
    }
  }
}
</script>
