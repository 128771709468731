<template>
  <div class="col sortable" :class="containerClass" @click.prevent="toggleDateSort">
    {{ headerTitle() }}
    <i
      class="fas fa-sort"
      :class="{
        'text-muted': locallySorted && dateRangeType === activeDateType,
        'fa-sort-up': dateRangeType === activeDateType && sortDirection === 'asc',
        'fa-sort-down': dateRangeType === activeDateType && sortDirection === 'desc'
      }"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import xconfig from '../js/xconfig'
import xlibs from '../js/xlibs'
import xcomputed from '../js/xcomputed'

export default {
  name: 'SortDateTableHeader',
  props: {
    containerClass: {
      type: String,
      default: null
    },
    dateType: {
      type: String,
      default: null
    },
    feature: {
      type: String,
      default: 'articles'
    }
  },
  computed: {
    isAdmin: xcomputed.isAdmin,
    isLive: xcomputed.isLive,
    isStatic: xcomputed.isStatic,
    ...mapGetters(['articleFilters', 'curatingFrom', 'collectionType', 'collectionName']),
    ...mapState({
      dateRangeType: (state) => state.articleFilters.sort.date.dateRangeType,
      sortDirection: (state) => state.articleFilters.sort.date.direction,
      locallySorted: (state) =>
        _.get(state, 'articleFilters.sort.field.type') && _.get(state, 'articleFilters.sort.field.direction')
    }),
    activeDateType() {
      return this.dateType ? this.dateType : this.dateRangeType
    }
  },
  methods: {
    headerTitle() {
      return _.get(xconfig.dateRangeTypes, [this.activeDateType, 'title'])
    },
    async toggleDateSort() {
      const sortDirectionValue = this.sortDirection === 'asc' ? 'desc' : 'asc'
      const sort = { date: { direction: sortDirectionValue, dateRangeType: this.activeDateType } }
      this.$store.dispatch('setArticleFilter', { sort })
      xlibs.applyFilters.call(this)
    }
  }
}
</script>
