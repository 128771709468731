import Vue from 'vue'
import Router from 'vue-router'
import home from './views/home.vue'
import login from './views/login.vue'

import staticHome from './views/staticHome.vue'
import incoming from './views/incoming.vue'
import excluded from './views/excluded.vue'
import ingested from './views/ingested.vue'
import spotlightsPending from './views/spotlightsPending'
import spotlightsSuppressed from './views/spotlightsSuppressed'
import spotlightsApproved from './views/spotlightsApproved'
import reports from './views/reports.vue'
import listManager from './views/listManager.vue'

Vue.use(Router)
const liveArticlesView = {
  template: `
    <div class="liveArticles">
      <router-view ref="articlesComponentRef"></router-view>
    </div>
  `,
  created() {
    this.$store.dispatch('setCollectionType', 'live')
  }
}
const liveSpotlightsView = {
  template: `
    <div class="liveSpotlights">
      <router-view></router-view>
    </div>
  `,
  created() {
    this.$store.dispatch('setCollectionType', 'live')
  }
}
const staticArticlesView = {
  template: `
    <div class="staticArticles">
      <router-view
      ></router-view>
    </div>
  `,
  created() {
    this.$store.dispatch('setCollectionType', 'static')
  }
}

const staticCollectionView = {
  template: `
    <div class="staticCollectionView">
      <div class="collectionHeader container-fluid">
        <div class="banner">
        <div class="container">
          <h2 class="logo-font">{{collectionName}}</h2>
        </div>
      </div>
        <router-link
        class="btn btn-sm btn-outline-secondary"
        to="/xman/static"
      >
        < back to Static Sets
      </router-link>
    </div>
      <router-view
      ></router-view>
    </div>
  `,
  created() {
    this.$store.dispatch('setCollectionName', this.collectionName)
  },
  computed: {
    collectionName() {
      const collectionName = this.$route.params.collectionName
      return collectionName
    }
  }
}
export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: home
    },
    {
      path: '/xman',
      name: 'home',
      component: home
    },
    {
      path: '/xman/live',
      component: liveArticlesView,
      children: [
        {
          path: '',
          name: 'live articles',
          props: true,
          redirect: {
            name: 'incoming live'
          }
        },
        {
          path: 'incoming',
          name: 'incoming live',
          props: true,
          component: incoming
        },
        {
          path: 'ingested',
          name: 'ingested live',
          props: true,
          component: ingested
        },
        {
          path: 'excluded',
          name: 'excluded live',
          props: true,
          component: excluded
        },
        {
          path: '*',
          redirect: {
            name: 'home'
          }
        }
      ]
    },
    {
      path: '/xman/spotlights',
      component: liveSpotlightsView,
      children: [
        {
          path: '',
          name: 'live spotlights',
          props: true,
          redirect: {
            name: 'spotlightsPending live'
          }
        },
        {
          path: 'spotlightsPending',
          name: 'spotlightsPending live',
          props: true,
          component: spotlightsPending
        },
        {
          path: 'spotlightsSuppressed',
          name: 'spotlightsSuppressed live',
          props: true,
          component: spotlightsSuppressed
        },
        {
          path: 'spotlightsApproved',
          name: 'spotlightsApproved live',
          props: true,
          component: spotlightsApproved
        },
        {
          path: '*',
          redirect: {
            name: 'home'
          }
        }
      ]
    },
    {
      path: '/xman/static',
      component: staticArticlesView,
      children: [
        {
          path: '',
          name: 'static articles',
          props: true,
          component: staticHome
        },
        {
          path: 'collection/:collectionName',
          props: true,
          component: staticCollectionView,
          children: [
            {
              path: '',
              redirect: {
                name: 'incoming static',
                props: true
              }
            },
            {
              path: 'incoming',
              name: 'incoming static',
              props: true,
              component: incoming
            },
            {
              path: 'ingested',
              name: 'ingested static',
              props: true,
              component: ingested
            },
            {
              path: 'excluded',
              name: 'excluded static',
              props: true,
              component: excluded
            }
          ]
        },
        {
          path: '*',
          redirect: {
            name: 'home'
          }
        }
      ]
    },
    {
      path: '/xman/reports',
      component: reports
    },
    {
      name: 'listManager',
      path: '/xman/listManager',
      component: listManager
    },
    {
      name: 'login',
      path: '/login',
      component: login
    },
    {
      path: '*',
      redirect: {
        name: 'incoming live'
      }
    }
  ]
})
