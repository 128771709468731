<template>
  <div class="static-home-page">
    <div class="banner">
      <div class="container">
        <h1 class="logo-font">Static Sets</h1>
      </div>
    </div>
    <div class="container-fluid form-controls">
      <div class="form-row set-buttons">
        <div class="col-auto my-1">
          <button
            :class="{ active: filterCollectionViewType === 'all' }"
            type="button"
            class="btn-sm btn-outline-primary"
            @click="filterCollections('all')"
          >
            All Sets
          </button>
          <button
            :class="{ active: filterCollectionViewType === 'ongoing' }"
            type="button"
            class="btn-sm btn-outline-primary"
            @click="filterCollections('ongoing')"
          >
            Ongoing Sets
          </button>
          <button
            :class="{ active: filterCollectionViewType === 'completed' }"
            type="button"
            class="btn-sm btn-outline-primary"
            @click="filterCollections('completed')"
          >
            Completed Sets
          </button>
        </div>
      </div>
    </div>
    <div id="search-spinner" class="container-fluid">
      <div class="col screen-centered"><div class="spinner" /></div>
    </div>
    <div v-if="staticCollections" class="static-collections-list">
      <div class="container-fluid data-set-list">
        <div class="row header">
          <div class="col col-sm-1 status">Status</div>
          <div class="col col-sm-3 fileNameCol">Set Name</div>
          <div class="col col-sm-3 uploadedByCol">Assignee</div>
          <div class="col col-sm-2 uploadDateCol">Assigned Date (local)</div>
          <div class="col col-sm-1 reviewedCol">Articles Reviewed</div>
          <div class="col col-sm-1 totlaCol">Articles Total</div>
        </div>
        <div v-for="collection in staticCollections" :key="collection._id" class="row list-content">
          <div class="col col-sm-1 status text-center">
            <div v-if="collection.allArticlesReviewed">
              <i class="fas fa-flag-checkered" title="review completed" />
            </div>
          </div>
          <div class="col col-sm-3 fileNameCol">
            <router-link
              v-if="collection.totalArticleCount"
              :to="'/xman/static/collection/' + collection.collectionName"
              >{{ collection.collectionName }}</router-link
            >
            <span v-else>{{ collection.collectionName }}</span>
          </div>
          <div class="col col-sm-3 uploadedByCol">
            <assignUser
              :assignee="collection.assignee"
              :collection-name="collection.collectionName"
              :xman-users="xmanUsers"
              class="ml-5 mr-5 mt-auto"
              @assign-user="updateAssignedUser"
            />
          </div>
          <div class="col col-sm-2 uploadDateCol">
            {{ collection.assignedDate | moment('M/D/YY h:mm a') }}
          </div>
          <div class="col col-sm-1 reviewedCol">
            {{ collection.processedArticleCount }}
          </div>
          <div class="col col-sm-1 totlaCol">
            {{ collection.totalArticleCount }}
          </div>
        </div>
      </div>
    </div>

    <div v-if="!_.get(staticCollections, 'length')" class="static-collections-list">no sets</div>
  </div>
</template>

<script>
import assignUser from '../components/assignUser'
import xlibs from '../js/xlibs'
import xconfig from '../js/xconfig'
import xcomputed from '../js/xcomputed'

const { defaultUserSelection } = xconfig
export default {
  name: 'StaticHome',
  components: {
    assignUser
  },
  data() {
    return {
      currentUser: {},
      xmanUsers: [],
      filterCollectionViewType: 'all',
      staticCollections: [
        {
          allArticlesReviewed: false,
          assignedBy: '',
          assignedDate: 0,
          assignee: { _id: '', email: '' },
          collectionName: '',
          processedArticleCount: 0,
          totalArticleCount: 0,
          _id: ''
        }
      ]
    }
  },
  computed: {
    isAdmin: xcomputed.isAdmin
  },
  async mounted() {
    this.currentUser = xlibs.getUser()
    this.staticCollections = []
    this.allStaticCollections = []
    await this.loadStaticCollections()
    if (this.isAdmin) {
      this.xmanUsers = await xlibs.getXmanUsers(defaultUserSelection)
    }
  },
  methods: {
    async loadStaticCollections() {
      const url = '/api/static/collections'
      xlibs.showSearchSpinner()
      const { data } = await axios
        .get(url)
        .catch((error) => {
          xlibs.requestFailure(error)
        })
        .finally(() => {
          xlibs.showSearchSpinner(false)
        })
      this.staticCollections = _.get(data, 'staticCollections')
      this.allStaticCollections = this.staticCollections
    },
    async filterCollections(completionType) {
      switch (completionType) {
        case 'ongoing':
          this.filterCollectionViewType = completionType
          this.staticCollections = _.filter(this.allStaticCollections, {
            allArticlesReviewed: false
          })
          break
        case 'completed':
          this.filterCollectionViewType = completionType
          this.staticCollections = _.filter(this.allStaticCollections, {
            allArticlesReviewed: true
          })
          break
        default:
          this.filterCollectionViewType = 'all'
          this.staticCollections = this.allStaticCollections
      }
    },
    async updateAssignedUser(collectionName, assignee) {
      const url = `/api/static/collections/${collectionName}`
      const postPayload = {
        collectionName,
        assignee
      }
      xlibs.showSearchSpinner()
      const { data } = await axios
        .post(url, postPayload)
        .catch((error) => {
          xlibs.requestFailure(error)
        })
        .finally(() => {
          xlibs.showSearchSpinner(false)
        })
      const updatedCollectionIndex = _.findIndex(this.staticCollections, {
        collectionName
      })

      this.$set(this.staticCollections[updatedCollectionIndex], 'assignedDate', data.assignedDate)
    }
  }
}
</script>
