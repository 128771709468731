<template>
  <div v-if="documentHash" class="documentHash">
    <div class="label">documentHash:</div>
    <div class="hash"><buttonCopy :value="documentHash" class="ml-1" /></div>
  </div>
</template>

<script>
import buttonCopy from './buttons/buttonCopy'

export default {
  name: 'DocumentHashCopy',
  components: {
    buttonCopy
  },
  props: {
    documentHash: {
      type: String,
      default: ''
    }
  }
}
</script>
