<template>
  <div v-if="!_.isEmpty(curationDisabled)" class="curation-note">
    <h6>This item has been curated since this page loaded.</h6>
    <div>
      Curated by <b :class="{ me: _.startsWith(curator, 'me') }">{{ curator }}</b>
    </div>
    <div>
      Curation Action: <b :class="curationDisabled.curationType">{{ curationDisabled.curationType }}</b>
    </div>
    <div>
      Curated from Page: <b>{{ curationDisabled.curationView }}</b>
    </div>
    <p></p>
    <p><a herf="#" class="refresh" @click.prevent="resetPage()">Refresh</a> or Change filter to update view</p>
  </div>
</template>

<script>
import xlibs from '../js/xlibs'
import xcomputed from '../js/xcomputed'

export default {
  name: 'CurationDisabledNote',
  props: {
    curationDisabled: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  computed: {
    ...xcomputed,
    curator() {
      const user = xlibs.getUser()
      const { _id: userId } = user
      const { userId: curationUserId } = this.curationDisabled
      const { curator: curationUsername } = this.curationDisabled
      const curatorLabel = curationUserId === userId ? `me (${curationUsername})` : curationUsername
      return curatorLabel
    }
  },
  methods: {
    resetPage() {
      this.$emit('resetPage')
    }
  }
}
</script>
