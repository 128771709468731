<template>
  <a
    v-inview:class="{ onpage: true }"
    v-inview:class.leave="{ onpage: false }"
    class="exclude"
    data-toggle="modal"
    data-target="#articleExcludeModal"
    href="#"
    title="Exclude All"
    :tabindex="!_.isEmpty(article.curationDisabled) ? -1 : 0"
    :class="{
      disabled: !_.isEmpty(article.curationDisabled) || featureSwitches.disableArticleCuration
    }"
    @click.prevent="openExcludeArticle(article._id, article.articlePubDateMs)"
    ><i class="fa fa-times-circle"
  /></a>
</template>

<script>
import { mapGetters } from 'vuex'
import xcomputed from '../../js/xcomputed'
import xlibs from '../../js/xlibs'
const { openExcludeArticle } = xlibs

export default {
  props: {
    article: {
      type: Object,
      default: null
    },
    articleIndex: {
      type: Number,
      default: null
    }
  },
  computed: {
    featureSwitches: xcomputed.featureSwitches,
    ...mapGetters([
      'articles',
      'articleFilters',
      'excludeModalArticle',
      'curatingFrom',
      'collectionType',
      'collectionName'
    ])
  },
  methods: {
    openExcludeArticle
  }
}
</script>
