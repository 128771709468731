<template>
  <div class="form-row">
    <div class="col-auto m-1">
      Score Modified:
      <select
        id="score-modified-filter"
        :value="ScoreModified"
        name="score-modified-filter"
        class="form-control-sm"
        tabindex="-1"
        @change="updateScoreModified"
      >
        <option v-for="option in formOptions" :key="option.key" :value="option.key">
          {{ option.key }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import xlibs from '../../js/xlibs'
import xcomputed from '../../js/xcomputed'

export default {
  name: 'ControlScoreModifiedFilter',
  props: [],
  computed: {
    isAdmin: xcomputed.isAdmin,
    isLive: xcomputed.isLive,
    isStatic: xcomputed.isStatic,
    formOptions() {
      let options = [{ key: 'any' }, { key: 'true' }, { key: 'false' }]
      return options
    },
    ...mapGetters(['articles', 'articleFilters', 'curatingFrom', 'collectionType', 'collectionName']),
    ...mapState({
      ScoreModified: (state) => state.articleFilters.polarityScoreModified
    })
  },
  methods: {
    async updateScoreModified(e) {
      this.$store.dispatch('setArticleFilter', { polarityScoreModified: e.target.value })
      xlibs.applyFilters.apply(this)
    }
  }
}
</script>
