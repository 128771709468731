<template>
  <div class="entities" :class="currentPipeline">
    <div v-if="_.isEmpty(entity)">
      <b>Missing Entity</b> on article for
      <div>tvlId: {{ orgTvlId }}</div>
      <div>category: {{ category }}</div>
    </div>
    <table v-if="!_.isEmpty(entity)" class="table entities-cats-matrix" style="table-layout: fixed">
      <thead>
        <tr>
          <th scope="col" class="entityHeader px-0 sticky-top" :class="currentPipeline">
            <div class="entityName">
              <div class="displayName">
                {{ getEntityName(entity) }}
                <span>
                  <b-button
                    :id="`${getDynamicTag('aliasCollapse', getEntityId(entity), articleIndex, currentPipeline)}`"
                    type="button"
                    data-placement="auto"
                    class="btn btn-company-info"
                  >
                    <i class="fas fa-info-circle"></i>
                  </b-button>
                  <b-popover
                    triggers="click blur"
                    :target="getDynamicTag('aliasCollapse', getEntityId(entity), articleIndex, currentPipeline)"
                  >
                    <span class="noSelect">
                      <u>{{ getEntityTypeLabel(currentPipeline) }}</u
                      ><buttonCopy :value="getEntityId(entity)" title="Copied company id"
                    /></span>
                    <div v-if="entity.primaryInstrumentTvlId" class="mb-1">
                      <span class="noSelect">
                        <u>primaryInstrumentId</u>
                        <buttonCopy :value="entity.primaryInstrumentTvlId" title="Copied primaryInstrumentId"
                      /></span>
                    </div>
                    <div v-if="entity.symbol" class="mb-1">
                      <u>Ticker</u> <span class="symbol"> {{ entity.symbol }} </span>
                    </div>
                    <ul v-if="entity.internalAliases" class="aliasList">
                      <li
                        v-for="(alias, aliasIndex) in getAliases(entity.internalAliases)"
                        :key="`${currentPipeline}_${articleIndex}_${alias}_${aliasIndex}`"
                        class="alias"
                      >
                        {{ alias }}
                      </li>
                    </ul>
                  </b-popover>
                </span>
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <template>
          <tr>
            <td class="lens-label" :class="[lens.key, currentPipeline]" colspan="1">
              {{ _.toUpper(lens.key) }} {{ currentPipeline }}
            </td>
          </tr>
          <template>
            <tr :key="`catLableRow_${articleIndex}_${lens.key}_${category}`">
              <td class="cat-label" :class="lens.key" colspan="1">{{ category }}</td>
            </tr>
            <tr :key="`catScoreRow_${articleIndex}_${lens.key}_${category}`">
              <td class="catScore entityCategory px-1" :class="currentPipeline">
                <spotlightCatScore
                  :spotlight-id="spotlightId"
                  :spotlight="spotlight"
                  :entity="entity"
                  :category="category"
                  :lens="lens.key"
                  :article-id="article._id"
                  :original-score="getOriginalScore(lens.key, entity, category)"
                  :current-score="getCurrentScore(lens.key, entity, category)"
                />
              </td>
            </tr>
          </template>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import xentities from '../../js/xentities'
import xentitiesComputed from '../../js/xentitiesComputed'
import xconfig from '../../js/xconfig'
import buttonCopy from '../buttons/buttonCopy'
import ButtonCopy from '../buttons/buttonCopy.vue'
import spotlightCatScore from './spotlightCatScore.vue'

const { pipelines } = xconfig

export default {
  name: 'SpotlightPolarity',
  components: {
    buttonCopy,
    spotlightCatScore,
    ButtonCopy
  },
  filters: {
    round: function (value) {
      if (typeof value !== 'number') return value
      return Math.round(value)
    }
  },
  props: {
    article: {
      type: Object,
      default: null
    },
    articleIndex: {
      type: Number,
      default: null
    },
    spotlight: {
      type: Object,
      default: null
    },
    spotlightId: {
      type: String,
      default: null
    },
    currentPipeline: {
      type: String,
      default: null
    },
    spotlightLens: {
      type: String,
      default: null
    },
    orgTvlId: {
      type: String,
      default: null
    },
    category: {
      type: String,
      default: null
    },
    curationChanges: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...xentitiesComputed,
    lenses() {
      const spotlightsEnabledLenses = _.filter(xconfig.lenses, 'spotlightsEnabled')
      return spotlightsEnabledLenses
    },
    lens() {
      const lens = _.find(this.lenses, { key: this.spotlightLens })
      return lens || { key: '' }
    },
    entity() {
      const entity = _.find(_.get(this, 'article.associationTags'), { tvlId: this.orgTvlId })
      return entity
    },
    spotlightChangePath: function () {
      return _.get(this, 'spotlightId')
    },
    ...mapGetters([
      'spotlights',
      'articleFilters',
      'curatingFrom',
      'collectionType',
      'collectionName',
      'spotlightChanges'
    ]),
    polarityLevels() {
      const levels = _.map(xconfig.polarityScoreLevels, (polarityLevel) => {
        return { value: polarityLevel.level, text: polarityLevel.level.toString() }
      })
      return levels
    },
    pipelines() {
      return pipelines
    }
  },
  methods: {
    ...xentities,
    lensKey(lens) {
      return pipelines.paths[this.currentPipeline].scores[lens]
    },
    getCurrentScore(lens, entity, category) {
      const scorePath = `${this.lensKey(lens)}.${category}`
      const score = _.get(entity, scorePath)
      const changes = _.get(this, `curationChanges.${this.spotlightId}`, {})
      const currentScore = _.get(changes, 'curated.score', score)
      return currentScore
    },
    getOriginalScore(lens, entity, category) {
      const scorePath = `${this.lensKey(lens)}.${category}`
      const score = _.get(entity, scorePath)
      const changes = _.get(this, `curationChanges.${this.spotlightId}`, {})
      const originalVal = _.get(changes, 'original.score')
      const originalScore = originalVal || score
      return originalScore
    }
  }
}
</script>
