<template>
  <div style="margin-top: 10px" class="form-row">
    <div class="col-auto my-1">
      <b-row class="my-1">
        <b-col class="col-sm-auto">
          <label for="input-spotlightId">Spotlight Id:</label>
        </b-col>
        <b-col class="col-sm-auto">
          <b-form-input
            id="input-spotlightId"
            ref="spotlightId"
            v-model="localSpotlightId"
            type="search"
            placeholder="Enter Spotlight Id (ignores other selections)"
            trim
            debounce="500"
            tabindex="-1"
            size="sm"
            :maxlength="maxLength"
            :state="validation"
          ></b-form-input>
          <b-form-invalid-feedback :state="validation"> Must be 36 characters long. </b-form-invalid-feedback>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import xlibs from '../../js/xlibs'
import xcomputed from '../../js/xcomputed'
import xconfig from '../../js/xconfig'

export default {
  name: 'ControlSpotlightId',
  props: [],
  computed: {
    isAdmin: xcomputed.isAdmin,
    isLive: xcomputed.isLive,
    isStatic: xcomputed.isStatic,
    ...mapGetters(['articles', 'articleFilters', 'curatingFrom', 'collectionType', 'collectionName']),
    ...mapState({
      spotlightId: (state) => _.get(state, 'articleFilters.spotlightId')
    }),
    localSpotlightId: {
      get: function () {
        return this.spotlightId
      },
      set: function (newValue) {
        this.updateSpotlightId(newValue)
      }
    },
    maxLength: () => xconfig.formsConfig.spotlightId.maxLength,
    validation() {
      const validCheck = _.size(this.localSpotlightId) == this.maxLength
      const valid = !_.size(this.localSpotlightId) ? null : validCheck
      return valid
    },
    isValid() {
      return this.validation === null || this.validation
    }
  },
  methods: {
    async updateSpotlightId(value) {
      const spotlightId = value.substring(0, this.maxLength)
      await this.$store.dispatch('setArticleFilter', { spotlightId: spotlightId })
      if (this.isValid) {
        await xlibs.applyFilters.apply(this)
      }
      if (!_.isEmpty(spotlightId)) this.$refs.spotlightId.$el.focus()
    }
  }
}
</script>
