<template>
  <div v-if="!_.isEmpty(article)" class="articleMetadata">
    <h5><span v-if="spotlightMode">Spotlight </span>Article Metadata</h5>

    <table class="table table-bordered table-sm">
      <thead>
        <tr>
          <th scope="col">Language</th>
          <th scope="col">Source</th>
          <th scope="col">Pub Date</th>
          <th scope="col">IREL curation</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td scope="row">{{ getLanguage(article.humanLanguage) }}</td>
          <td>{{ _.get(article, 'source_name') || _.get(article, 'source.name', article.source) }}</td>
          <td>{{ formattedDate(article.articlePubDateMs) }}</td>
          <td></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import xcomputed from '../../js/xcomputed'
import xlibs from '../../js/xlibs'

export default {
  name: 'ArticleMetadata',
  props: {
    article: {
      type: Object,
      default: null
    },
    spotlightMode: {
      type: String,
      default: null
    }
  },
  computed: {
    ...xcomputed
  },
  methods: {
    ...xlibs
  }
}
</script>
