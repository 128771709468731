<template>
  <div class="login-page">
    <div class="container page">
      <div class="row">
        <div class="xman-login-logo mx-auto">
          <div class="xman-mode">{{ xmanMode }}</div>
          <h1 class="xman-logo" :class="xmanMode">XMAN</h1>
          <h4 class="xman-title">{{ xmanMode }} Exclusion Manager</h4>
          <h5>for Insight360</h5>
        </div>
      </div>
      <div class="row login-table">
        <div class="col-md-5 offset-md-3 col-xs-9">
          <ul v-if="errors" class="error-messages">
            <li v-for="(v, k) in errors" :key="k">{{ v }}</li>
          </ul>
          <form name="login-form" @submit.prevent="login(email, password)">
            <fieldset class="form-group">
              <input v-model="email" class="form-control email-input" type="text" placeholder="Email" />
            </fieldset>
            <fieldset class="form-group">
              <input v-model="password" class="form-control password-input" type="password" placeholder="Password" />
            </fieldset>
            <button class="btn btn-primary float-right">Log in</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import xlibs from '../js/xlibs'
import xcomputed from '../js/xcomputed'
import xconfig from '../js/xconfig'
// import { mapState } from "vuex";
// import { LOGIN } from "@/store/actions.type";

const authDomain = window.xmanConfig.authUrl
const loginUrl = `${authDomain}/token`
const getUserUrl = `${authDomain}/userWithTokenOld`
const authCookieName = xconfig.authCookieName
export default {
  name: 'LoginXman',
  data() {
    return {
      email: null,
      password: null,
      errors: []
    }
  },
  computed: {
    ...xcomputed
  },
  mounted() {
    const authToken = this.getAuthCookie()
    const hasUser = JSON.parse(localStorage.getItem('user'))
    if (authToken) {
      if (!hasUser) {
        this.getUser(authToken)
      } else {
        if (window.xmanConfig.roles.indexOf(hasUser.roleId) > -1) {
          this.$router.push('xman')
          return false
        }
        this.clearUserSession()
      }
    }
    this.focusEmail()
  },
  methods: {
    focusEmail() {
      $('.email-input').focus()
    },
    getAuthCookie() {
      return this.$cookies.get(authCookieName)
    },
    clearUserSession() {
      this.$cookies.remove(authCookieName)
      localStorage.removeItem('user')
      this.focusEmail()
    },
    async getUser(token) {
      xlibs.showPageSpinner()
      const response = await axios
        .post(getUserUrl, { token })
        .catch((error) => {
          this.errors.push(error)
        })
        .finally(() => {
          xlibs.showPageSpinner(false)
        })
      const user = _.get(response, 'data.data')
      if (user) {
        localStorage.setItem('user', JSON.stringify(user))
        if (user.roleId && window.xmanConfig.roles.indexOf(user.roleId) > -1) {
          this.$router.push('xman')
          return false
        }
        this.errors.push('Invalid permissions')
      } else {
        this.errors.push('Error getting user')
      }
      this.clearUserSession()
    },
    async login(email, password) {
      this.errors = []
      if (!email || !password) {
        this.errors.push('Missing email or passsword')
        return false
      }

      const postPayload = { username: email, password }
      xlibs.showPageSpinner()
      const response = await axios
        .post(loginUrl, postPayload)
        .catch((error) => {
          const errorMessage = _.get(error, 'response.data.message', 'unknown login error')
          this.errors.push(errorMessage)
          this.clearUserSession()
        })
        .finally(() => {
          xlibs.showPageSpinner(false)
        })
      const token = _.get(response, 'data.data.token')
      if (token) {
        this.$cookies.set(authCookieName, token, '8h')
        await this.getUser(token)
        xlibs.authenitcateSocket(this)
      } else if (_.get(this, 'errors.length') < 1) {
        this.errors.push('Login error')
        this.clearUserSession()
      }
    }
  }
}
</script>
