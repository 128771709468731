<template>
  <div class="form-row">
    <div class="col-auto m-1">
      Company Groups to Review:
      <select
        id="company-reporting-filter"
        :value="companyReportingType"
        name="company-reporting-filter"
        class="form-control-sm"
        tabindex="-1"
        @change="updateCompanyReporting"
      >
        <option value="all">All Companies</option>
        <option value="reportPublishing">Report-Publishing Cos</option>
        <option value="finance">finance Cos</option>
        <option value="techMedia">Tech & Media Cos</option>
        <option value="normal">Normal Cos</option>
      </select>
    </div>
    <div class="col-auto my-1" :if="isAdmin">
      <router-link class="btn btn-sm btn-outline-secondary" to="/xman/listManager" tabindex="-1"
        >view lists</router-link
      >
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import xlibs from '../../js/xlibs'
import xcomputed from '../../js/xcomputed'

export default {
  name: 'ControlCompanyReporting',
  props: [],
  computed: {
    isAdmin: xcomputed.isAdmin,
    isLive: xcomputed.isLive,
    isStatic: xcomputed.isStatic,
    ...mapGetters(['articles', 'articleFilters', 'curatingFrom', 'collectionType', 'collectionName']),
    ...mapState({
      companyReportingType: (state) => state.articleFilters.companyReportingType
    })
  },
  methods: {
    async updateCompanyReporting(e) {
      this.$store.dispatch('setArticleFilter', { companyReportingType: e.target.value })
      xlibs.applyFilters.apply(this)
    }
  }
}
</script>
