import xconfig from './xconfig'

const { GRID_COLUMNS } = xconfig

const xentitiesComputed = {
  scoreSizing() {
    const gridBase = 'col-sm-'
    const lensSizing = {
      sasb: {
        collapsedSize: 0,
        sizeClass: 'expanded'
      },
      sdg: {
        collapsedSize: 0,
        sizeClass: 'expanded'
      }
    }
    let minimized = 0
    _.each(this.expandedScores, (expandScore, key) => {
      if (!expandScore) {
        lensSizing[key].collapsedSize = 1
        lensSizing[key].sizeClass = 'collapsed'
        minimized += 1
      }
    })
    const allMinimized = minimized === _.size(lensSizing)
    const remaining = GRID_COLUMNS - minimized
    const remainingSlots = _.size(lensSizing) - minimized
    const sizing = Math.floor(remaining / remainingSlots)
    const classes = _.reduce(
      lensSizing,
      (acc, lens, key) => {
        const lensSize = allMinimized ? GRID_COLUMNS / _.size(lensSizing) : lens.collapsedSize || sizing

        _.set(acc, [key, 'gridClass'], `${gridBase}${lensSize}`)
        _.set(acc, [key, 'sizeClass'], lens.sizeClass)
        return acc
      },
      {}
    )
    return { classes, allMinimized }
  },
  detailsGridClass() {
    return this.scoreSizing.allMinimized ? 'col-sm-7' : 'col-sm-3'
  },
  entitiesGridClass() {
    return this.scoreSizing.allMinimized ? 'col-sm-3' : 'col-sm-7'
  },
  entityNameGridClass() {
    return this.scoreSizing.allMinimized ? 'col-sm-9' : 'col-sm-3'
  },
  scoresSectionGridClass() {
    return this.scoreSizing.allMinimized ? 'col-sm-3' : 'col-sm-9'
  },
  lenses() {
    return xconfig.lenses
  }
}

export default xentitiesComputed
