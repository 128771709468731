<template>
  <div
    id="articleModal"
    class="modal fade bd-example-modal-lg"
    :tabindex="tabindex"
    role="dialog"
    aria-labelledby="myLargeModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-full">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ getTitle(modalArticle) }}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true"> &times; </span>
          </button>
        </div>
        <div class="modal-body">
          <div v-if="_.get(modalArticle, 'humanLanguage', 'en') !== 'en'" class="langButton position-absolute">
            <button
              type="button"
              class="btn-sm btn-secondary"
              :class="{ active: !getOriginal }"
              @click="toggleOriginalLanguage"
            >
              {{ languageButtonLabel }}
            </button>
          </div>
          <!-- <a class="flagArticle" onclick="displayFlagForm()"> Flag article </a>
          <div id="flag" /> -->
          <h1>{{ getTitle(modalArticle) }}</h1>
          <span
            >by {{ _.get(modalArticle, 'authors[0].fullName', modalArticle.author) }} |
            {{ _.get(modalArticle, 'source.name', modalArticle.source) }} |
            {{ formattedDate(modalArticle.articlePubDateMs) }}</span
          >
          <br />
          <div class="languageSection">
            <span class="humanLanguage badge badge-info text-uppercase">{{
              getLanguage(modalArticle.humanLanguage)
            }}</span>
          </div>
          <div v-if="isAdmin" class="versionButtons">
            <br />
            <button
              v-if="_.get(modalArticle, 'dpv2.associationTags[0]')"
              type="button"
              class="btn-sm"
              :class="currentVersionView === 'v2' ? 'btn-purple' : 'btn-secondary'"
              @click="setArticleVersion('v2')"
            >
              V2
            </button>
            <button
              v-if="_.get(modalArticle, 'dpv3.associationTags[0]')"
              type="button"
              class="btn-sm"
              :class="currentVersionView === 'v3' ? 'btn-purple' : 'btn-secondary'"
              @click="setArticleVersion('v3')"
            >
              V3
            </button>
          </div>
          <br />
          <div class="articleIdentifiers">
            <i>documentHash:</i> <b>{{ _.get(modalArticle, 'documentHash') }}</b>
            <br />
            <i>article.id:</i> <b>{{ _.get(modalArticle, '_id') }}</b>
            <i>articlePubDateMs:</i>
            <b>{{ _.get(modalArticle, 'articlePubDateMs') }}</b
            ><br />
            <i>GUID:</i> <b>{{ _.get(modalArticle, 'GUID') }}</b>
          </div>
          <!--{{if role !== 'exclusionAnalyst'}} -->
          <div class="article-info">
            <div v-if="isAdmin" class="articleTagData">
              <div
                v-if="currentVersionView === 'v3' || (currentVersionView === 'v2' && _.get(modalArticle, dpVersion))"
              >
                Tagged Companies:
                <span
                  v-for="(company, index) in modalArticle[dpVersion].associationTags"
                  :key="`${index}-${company.tvlId}`"
                >
                  <span v-if="index > 0"> , </span>{{ _.get(company, 'longName') }}
                </span>
                <br /><br />
              </div>
              <div v-if="_.get(modalArticle, `dp${currentVersionView}.nlpVersion`)">
                NLP Version:
                {{ _.get(modalArticle, `dp${currentVersionView}.nlpVersion`) }}
              </div>
              <!-- V2/V3 -->
              <div
                v-if="currentVersionView === 'v3' || (currentVersionView === 'v2' && _.get(modalArticle, dpVersion))"
              >
                <div v-for="entity in modalArticle[dpVersion].associationTags" :key="`entity-${entity.tvlId}`">
                  <div>
                    <h5 class="v3Company">Company: {{ entity.longName }}</h5>
                    <div v-for="(score, name) in entity[scoreKey]" :key="`${entity.tvlId}_${name}`">
                      <span v-if="_.get(modalArticle, `${dpVersion}.${name}`) && _.size(score)">
                        NLP Version for {{ name }}:
                        {{ _.get(modalArticle, `${dpVersion}.${name}.nlpVersion`) }}
                      </span>
                      <table v-if="_.size(score)" class="scores table table-dark table-hover table-bordered">
                        <thead>
                          <th width="80%">{{ name }} Categories</th>
                          <th width="20%">Score</th>
                        </thead>
                        <tbody>
                          <tr v-for="(value, key) in score" :key="`${name}scores-${value}-${key}`">
                            <td>{{ key }}</td>
                            <td>{{ value }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <br />
                  </div>
                </div>
              </div>
            </div>
            <ul v-if="getSummarisedBullets(modalArticle)">
              <li v-for="bullet in getSummarisedBullets(modalArticle)" :key="bullet">
                {{ bullet }}
              </li>
            </ul>
          </div>
          <div class="article-body" v-html="getSummaryText(modalArticle)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import xconfig from '../js/xconfig'
import xlibs from '../js/xlibs'
import xcomputed from '../js/xcomputed'

export default {
  name: 'ArticleModal',
  data() {
    return {
      currentVersionView: '',
      getOriginal: false,
      languageButtonLabel: xconfig.languageButtonLabel.off,
      tabindex: -1
    }
  },
  computed: {
    ...mapGetters(['modalArticle']),
    formattedDate: xcomputed.formattedDate,
    isAdmin: xcomputed.isAdmin,
    initialVersion() {
      let version = ''
      if (_.get(this, 'modalArticle.dpv3')) version = 'v3'
      if (_.get(this, 'modalArticle.dpv2')) version = 'v2'
      return version
    },
    dpVersion() {
      return `dp${this.currentVersionView}`
    },
    scoreKey() {
      return this.currentVersionView === 'v2' ? 'categories' : 'scores'
    }
  },
  watch: {
    initialVersion: function (val) {
      this.currentVersionView = val
    }
  },
  mounted() {
    const self = this
    $('#articleModal').on('shown.bs.modal', function () {
      self.tabindex = 0
      $('#articleModal').find(':tabbable').first().focus()
    })
    $('#articleModal').on('hide.bs.modal', function () {
      self.clearModal()
      self.tabindex = -1
    })
  },
  destroyed() {
    $('#articleModal').off('shown.bs.modal')
    $('#articleModal').off('hidden.bs.modal')
  },
  methods: {
    clearModal() {
      this.currentVersionView = ''
      this.$store.dispatch('setModalArticle', {})
    },
    setArticleVersion(version = 'v2') {
      if (_.get(this.modalArticle, `dp${version}.associationTags`, _.get(this.modalArticle, `dp${version}.tags`))) {
        this.currentVersionView = version
      }
    },
    getLanguage: xlibs.getLanguage,
    getSummaryText(article) {
      const getOriginal = this.getOriginal
      return xlibs.getSummaryText({ article, getOriginal, html: true })
    },
    getSummarisedBullets(article) {
      return xlibs.getSummarisedBullets(article, this.getOriginal)
    },
    getTitle(article) {
      return xlibs.getTitle(article, this.getOriginal)
    },
    toggleOriginalLanguage() {
      const self = this
      self.getOriginal = !self.getOriginal
      self.languageButtonLabel = self.getOriginal ? xconfig.languageButtonLabel.on : xconfig.languageButtonLabel.off
    }
  }
}
</script>
