<template>
  <div class="row entitiesRow">
    <div class="col" :class="entityNameGridClass">Companies</div>
    <div class="col" :class="scoresSectionGridClass">
      <div class="row esgRow scoresRow">
        <div
          v-for="lens in lenses"
          :key="lens.key"
          class="col text-center"
          :class="scoreSizing.classes[lens.key].gridClass"
        >
          <b-link
            v-b-tooltip.hover.noninteractive
            href="#"
            class="expandable text-dark"
            :class="_.get(expandedScores, lens.key) ? 'expanded' : 'collapsed'"
            :title="getTooltipTitle(_.get(expandedScores, lens.key))"
            @click.prevent="toggleExpand(lens.key)"
            >{{ lens.name }}
            <b-icon v-if="_.get(expandedScores, lens.key)" icon="arrows-collapse" scale="1.1" rotate="90"></b-icon
          ></b-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import xentities from '../../js/xentities'
import xentitiesComputed from '../../js/xentitiesComputed'

export default {
  name: 'EntitiesTableHeader',
  computed: {
    ...xentitiesComputed,
    ...mapGetters(['expandedScores'])
  },
  methods: {
    ...xentities,
    async toggleExpand(key) {
      this.$store.dispatch('toggleExpandedScore', key)
    },
    getTooltipTitle(expanded) {
      const action = expanded ? 'collapse' : 'expand'
      return `Click to ${action}`
    }
  }
}
</script>
