<template>
  <div style="margin-top: 10px" class="form-row">
    <div v-if="isAdmin" class="col-auto my-1">
      <select v-model="selectedAssignee" name="assign-user" class="form-control-sm" @change="assignUser()">
        <option v-for="user in xmanUsers" :key="user._id" :value="user">
          {{ user.email }}
        </option>
      </select>
      <span v-if="selectedAssignee._id === currentUser.userId">(me)</span>
    </div>
    <div v-else class="col-auto my-1">
      {{ selectedAssignee.email }}
      <span v-if="selectedAssignee._id === currentUser.userId">(me)</span>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import xlibs from '../js/xlibs'
import xconfig from '../js/xconfig'
import xcomputed from '../js/xcomputed'

const { defaultUserSelection } = xconfig
export default {
  name: 'AssignUser',
  props: ['assignee', 'collectionName', 'xmanUsers'],
  data() {
    return {
      currentUser: {},
      selectedAssignee: defaultUserSelection
    }
  },
  computed: {
    isAdmin: xcomputed.isAdmin,
    ...mapGetters(['collectionType'])
  },
  async mounted() {
    this.currentUser = xlibs.getUser()
    this.selectedAssignee = this.assignee || defaultUserSelection
  },
  methods: {
    async assignUser() {
      this.$emit('assign-user', this.collectionName, this.selectedAssignee)
    }
  }
}
</script>
