<template>
  <div class="form-row">
    <div class="col-auto my-1">
      Pipeline:
      <select
        id="pipeline-filter"
        :value="pipeline"
        name="pipeline-filter"
        class="form-control-sm"
        tabindex="-1"
        @change="updatePipeline"
      >
        <option value="v2">Version 2</option>
        <option value="v3">Version 3</option>
      </select>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import xlibs from '../../js/xlibs'
import xcomputed from '../../js/xcomputed'

export default {
  name: 'ControlPipelineFilter',
  props: [],
  computed: {
    isAdmin: xcomputed.isAdmin,
    isLive: xcomputed.isLive,
    isStatic: xcomputed.isStatic,
    ...mapGetters(['articles', 'articleFilters', 'curatingFrom', 'collectionType', 'collectionName']),
    ...mapState({
      pipeline: (state) => state.articleFilters.pipeline
    })
  },
  methods: {
    async updatePipeline(e) {
      this.$store.dispatch('setArticleFilter', { pipeline: e.target.value })
      xlibs.applyFilters.apply(this)
    }
  }
}
</script>
